import React from 'react';
import { Consumer } from '../context';
import { NavLink } from "react-router-dom";
import { IMG_URL } from '../api/url';

class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			lista_modulos: [],
			modulos: this.initModulos(),
			loading: false,
		}
		this.loadModulos();
    }

	shouldComponentUpdate(nextProps, nexState) {
		if (this.state.modulos !== nexState.modulos) {
			return true;
		}
		if (this.state.loading !== nexState.loading) {
			return true;
		}
		return false;
	}

	initModulos() {
		let lsModulos = localStorage.getItem('mrd_modulos');
		if (lsModulos) {
			lsModulos = JSON.parse(lsModulos);
			return lsModulos;
		} else {
			return [];
		}
	}

	async loadModulos() {
		const { context } = this.props;
        if (context) {
			const lista_modulos = await context.loadModulos();
			if(Array.isArray(lista_modulos)) {
				this.setState({ lista_modulos });
			}
			this.loadPermisos();
        }
	}

    async loadPermisos() {
        const { context } = this.props;
        if (context) {
			this.setState({loading: true});
            const { lectura } = await context.getPermisos(context.token);
            if (lectura) {
                const modulos = [];
                lectura.forEach(l => {
                    const modulo = this.state.lista_modulos.find(lm => lm.id_modulo === l);
                    if (modulo) {
                        modulos.push(modulo);
                    }
				});
				let lsModulos = localStorage.getItem('mrd_modulos');
				if (lsModulos) {
					lsModulos = JSON.parse(lsModulos);
					if (lsModulos !== modulos) {
						localStorage.setItem('mrd_modulos', JSON.stringify(modulos));
						this.setState({modulos});
					}
				} else {
					localStorage.setItem('mrd_modulos', JSON.stringify(modulos));
					this.setState({modulos});
				}
            }
			this.setState({loading: false});
		}
    }

    logout() {
        this.props.context.logout(this.props.history);
    }    

    opensidebar() {
        const bar = document.getElementById("sidebar-mobile");
		if(bar.classList.contains('open')) {
			bar.classList.remove('open');
		}else {
            bar.classList.add('open');
        }
    }

    imgError(img) {
		img.target.src =  "/images/user-back.png";
	}

    render() {
		let  { modulos } = this.state;
		const { imagen } = this.props.context;
        const { nombre } = this.props.context;
        return (
            <div>
				<div className="btn-column btn-menu column">
					<i className="fas fa-bars color-white btn-sidebar " onClick={this.opensidebar.bind(this)}></i>
				</div>
				<div className="column side-bar" id="sidebar-mobile" onClick={this.opensidebar.bind(this)}>
					<i className="fas fa-times close-btn color-white"></i>
					<div className="row full profile-row justify-center align-center">
						<div className="column">
							<img className="profile-img" src={`${IMG_URL}imagen?path=${imagen}`} onError={this.imgError.bind(this)} width="424" height="600" />
						</div>
						<div className="column profile-data">
							<p className="weight-bold">{nombre}</p>
							<p className="weight-regular"></p>
						</div>
					</div>
					<div className="white-space-32"></div>
					<div className="row nav-item full justify-start align-center">
						<NavLink
							to={`/home`}
							className="font-regular color-white align-center">
							<i className="fa fa-dashboard"></i>
							<span className="color-white weight-light">Inicio</span>
						</NavLink>
					</div>
					{modulos.map(m =>
						<div className="row nav-item full justify-start align-center" key={m.id_modulo}>
							<NavLink
								to={m.ruta}
								className="font-regular color-white align-center">
								<img src={m.icono} alt="" width="30" height="30"></img>
								<span className="color-white weight-light">{m.modulo}</span>
							</NavLink>
						</div>
					)}
					<div className="row nav-item full justify-start align-center">
						<a
							onClick={this.logout.bind(this)}
							className="font-regular color-white align-center">
							<i className="fas fa-sign-out-alt"></i>
							<span className="color-white weight-light">Cerrar sesión</span>
						</a>
					</div>
				</div>
            </div>
        )
    }
}

export default Consumer(SideBar);