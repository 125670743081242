import React, {useState, useEffect} from "react";
import {Consumer} from "../../context/index";
import Paginador from "../paginador/paginador";

const ListaAsistencia = (props) => {
	const [prevProps, setPrevProps] = useState({});
	const [listaAsistencia, setListaAsistencia] = useState([]);
	const [pageSelect, setPageSelect] = useState(1);

	useEffect(() => {
		if (props.listaAsistencia !== prevProps.listaAsistencia) {
			setListaAsistencia(props.listaAsistencia);
			setPrevProps(props);
		}
	}, [props.listaAsistencia]);

	function setPage(page) {
		setPageSelect(page);
	}

	return (
		<div className="full row align-center">
			<div className="column full">
				<div className="table-container">
					<div className="table-responsive">
						<table className="tabla-usuarios">
							<thead>
								<tr>
									<th className="text-left">Nombre</th>
									<th className="text-left">Correo</th>
									<th className="text-left">Teléfono</th>
								</tr>
							</thead>
							<tbody>
								{props.loadingListaAsistencia ? (
									<tr>
										<td colSpan="3">
											<div className="row justify-center">
												<i className="fas fa-sync fa-spin"></i>
											</div>
										</td>
									</tr>
								) : listaAsistencia.length === 0 ? (
									<tr>
										<td colSpan="3">
											<div className="row justify-center">No hay alumnos inscritos.</div>
										</td>
									</tr>
								) : (
									listaAsistencia.map((item, key) => (
										<tr key={key}>
											<td className="text-left">{item.nombre}</td>
											<td className="text-left">{item.correo}</td>
											<td className="text-left">{item.telefono}</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
				</div>

				<div className="row full">
					<div className="white-space-16"></div>
				</div>
				<Paginador page={pageSelect} pages={Math.ceil(listaAsistencia.length / 8)} setPage={setPage.bind(this)} />
			</div>
		</div>
	);
};

export default Consumer(ListaAsistencia);
