import React from "react";
import moment from "moment-timezone";
import cogoToast from "cogo-toast";

import {Consumer} from "../context";
import Request from "../api/apiService";
import Sidebar from "../components/sideBar";
import RowSesion from "../components/alumnos/rowSesion";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import SesionesForm from "../components/sesiones/form";
import Paginador from "../components/paginador/paginador";

const req = new Request();

class Sesiones extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id_modulo: 10, // supongo que va a quedar como 10
			loading: false,
			sesion: {},
			sesiones: [],
			usuario: {},
			filtro: "",
			page: 1,
			materias: [],
			horarios: [],
			profesores: [],
			materia_filtro: "0",
			horario_filtro: "0",
			maestro_filtro: "0",
		};
	}

	async componentDidMount() {
		await this.loadUser();
		await this.loadFilter();
	}

	async loadFilter() {
		let materias = await this.props.context.loadSesionesMaterias();
		let horarios = await this.props.context.loadSesionesHorario();
		let profesores = await this.props.context.loadSesionesProfesores();

		this.setState({materias: materias, profesores: profesores, horarios: horarios});
	}

	async openModal(sesion) {
		const modal = document.getElementById("sesionSelected");
		await this.setState({sesion: sesion});
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-dinamic-modal");
		}
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add("hide-dinamic-modal");
			modal.classList.remove("show-dinamic-modal");
		}
	}

	setPage(page) {
		this.setState({page});
	}

	clearData() {
		this.setState({sesion: {}});
	}

	async loadUser() {
		const mrd_user = window.localStorage.getItem("mrd_user");
		let session = JSON.parse(mrd_user);
		const data = {
			token: session.token,
		};
		const {token} = await req.post("decode", data);
		if (token) {
			this.setState({usuario: token});
		} else {
			cogoToast.error("No se pudieron obtener los datos del usuario.", {position: "top-right", heading: "OOPS!"});
		}
	}

	async loadSesiones() {
		this.setState({loading: true});

		if (this.state.filtro !== "") {
			const usuario = this.state.usuario;
			const {context} = this.props;
			const data = {
				id_modulo: this.state.id_modulo,
				token: context.token,
				id_usuario: usuario.id_usuario,
				filtro: this.state.filtro,
			};

			const res = await req.get("sesiones/filtrar", data);
			if (!res.error && !res.empty) {
				res.sesiones = res.sesiones.map((sesion) => {
					sesion.fecha = sesion.fecha.map((fch) => new Date(fch));
					return sesion;
				});
				this.setState({sesiones: res.sesiones, materia_filtro: "0", horario_filtro: "0", maestro_filtro: "0"});
			} else {
				this.setState({sesiones: [], materia_filtro: "0", horario_filtro: "0", maestro_filtro: "0"});
			}
		}
		this.setState({loading: false});
	}

	async saveInscripcion(event) {
		event.preventDefault();

		const usuario = this.state.usuario;
		const sesion = this.state.sesion;
		const {context} = this.props;
		if (await this.props.context.revisarTelefono(this.state.usuario)) {
			const data = {
				id_modulo: this.state.id_modulo,
				token: context.token,
				id_usuario: usuario.id_usuario,
				id_sesion: sesion.id_sesion,
			};

			const res = await req.post("sesiones/inscripcion", data);

			if (res.joined) {
				cogoToast.success("Inscripción realizada con éxito.", {position: "top-right", heading: "ESTÁS INSCRITO!"});
				this.closeModal("sesionSelected");

				let sesiones = this.state.sesiones;

				sesiones = sesiones.map((ss) => {
					if (ss.id_sesion == sesion.id_sesion) {
						ss.inscrito = 1;
					}
					return ss;
				});

				this.setState({sesiones: sesiones});
			} else {
				cogoToast.error(res.message || "Error Interno.", {position: "top-right", heading: "OOPS!"});
				this.setState({message: data.message, loadingSave: false});
			}
		}
	}

	reload() {
		this.loadSesiones();
	}

	render() {
		let sesiones = [];
		if (this.state.sesiones) {
			sesiones = this.state.sesiones;
			if (this.state.materia_filtro != "0") {
				sesiones = sesiones.filter((ss) => ss.materia == this.state.materia_filtro);
			}
			if (this.state.horario_filtro != "0") {
				sesiones = sesiones.filter((ss) => ss.horario == this.state.horario_filtro);
			}
			if (this.state.maestro_filtro != "0") {
				sesiones = sesiones.filter((ss) => ss.profesor == this.state.maestro_filtro);
			}
		}
		return (
			<div className="row full view">
				<Sidebar view="sesiones"></Sidebar>
				<div className="column full align-center">
					<div className="container column">
						<div className="row full header-options align-center">
							<div className="column full title">
								<h1 className="weight-light color-white">Sesiones</h1>
							</div>
						</div>
						<div className="row full">
							<div className="column full">
								<input
									type="text"
									name="name"
									id="buscar"
									placeholder="Buscar sesión..."
									className="input input-modals"
									required
									maxLength="150"
									autoComplete="off"
									onChange={(event) => {
										this.setState({filtro: event.target.value});
									}}
								/>
							</div>
							<div className="column quart padding-left-16">
								<button
									className="btn-options btn-orange-plus"
									onClick={() => {
										this.loadSesiones();
									}}
								>
									<i className="fas fa-search"></i>
									&nbsp;Buscar
								</button>
							</div>
						</div>
						<div className="row full">
							<div className="white-space-16"></div>
						</div>
						<div className="row full row-filter">
							<div className="column">
								<h2 className="weight-light color-white">Filtro:</h2>
							</div>
							<div className="column full padding-left-16">
								<select
									className="input input-modals"
									name="role"
									id="materia_filtro"
									name="materia_filtro"
									value={this.state.materia_filtro}
									onChange={(event) => {
										this.setState({materia_filtro: event.target.value});
									}}
								>
									<option value="0" selected>
										Materia
									</option>
									{this.state.materias.map((mat, key) => {
										return (
											<option key={key} value={mat.materia}>
												{mat.materia}
											</option>
										);
									})}
								</select>
							</div>

							<div className="column full padding-left-16">
								<select
									className="input input-modals"
									name="role"
									id="sesion_horario"
									name="sesion_horario"
									value={this.state.horario_filtro}
									onChange={(event) => {
										this.setState({horario_filtro: event.target.value});
									}}
								>
									<option value="0" selected>
										Horario
									</option>
									{this.state.horarios.map((hr, key) => {
										return (
											<option key={key} value={hr.horario}>
												{hr.horario}
											</option>
										);
									})}
								</select>
							</div>
							<div className="column full padding-left-16">
								<select
									className="input input-modals"
									name="role"
									id="maestro_filtro"
									name="maestro_filtro"
									value={this.state.maestro_filtro}
									onChange={(event) => {
										this.setState({maestro_filtro: event.target.value});
									}}
								>
									<option value="0" selected>
										Maestro
									</option>
									{this.state.profesores.map((pr, key) => {
										return (
											<option key={key} value={pr.nombre}>
												{pr.nombre}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className="row full">
							<div className="white-space-24"></div>
						</div>

						<RowSesion
							sesiones={sesiones.slice((this.state.page - 1) * 8, this.state.page * 8)}
							loading={this.state.loading}
							reload={this.reload.bind(this)}
							id_modulo={this.state.id_modulo}
							openModal={this.openModal.bind(this)}
						/>
						<div className="row full">
							<div className="white-space-24"></div>
						</div>
						<Paginador page={this.state.page} pages={Math.ceil(sesiones.length / 8)} setPage={this.setPage.bind(this)} />
						<div className="white-space-48"></div>
					</div>
				</div>
				<DinamicModal
					idModal={"sesionSelected"}
					sizeModalContainer={"normal"}
					title={"Sesiones"}
					showBtnSuccess={true}
					btnTextSuccess={"Inscribirse"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					success={this.saveInscripcion.bind(this)}
					loadingSave={this.state.loadingSave}
					closeModal={this.clearData.bind(this)}
				>
					<SesionesForm values={this.state.sesion} updateState={() => {}} readOnly={true} />
				</DinamicModal>
			</div>
		);
	}
}

export default Consumer(Sesiones);
