import React from 'react';
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import OrdenTrabajoForm from "../../components/ordenTrabajo/form";
import Request from '../../api/apiService';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import Paginador from '../paginador/paginador';
import { Consumer } from '../../context';
import Controles from './controles';
const req = new Request();

class TablaPresupuestos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 2,
            loading: false,
            orden_trabajo: {},
			presupuestos: [],
			page: 1,
			filtro: ''
		};
		this.formValues = {};
    }

    openModal(idModal, item) {
        const modal = document.getElementById(idModal);
        this.setState({orden_trabajo: item});
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }

	
	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

    clearData() {
        this.formValues = {};
    }

    async editarOrdenTrabajo(event) {
        this.setState({loading: true, message: ''});
		event.preventDefault();
		const { context } = this.props;
		const id_usuario = await context.getId(context.token);
		const { orden_trabajo, id_modulo } = this.state;
		const modificado = Array.isArray(orden_trabajo.modificado) ? orden_trabajo.modificado : [];
		modificado.push({id_usuario, fecha: moment().format('YYYY-MM-DD HH:mm:ss')});
        const data = {
			id_modulo: id_modulo,
			id_orden: orden_trabajo.id_orden,
			fecha: this.formValues.fecha,
			concepto: this.formValues.concepto,
			id_presupuesto: this.formValues.id_presupuesto,
			importe: this.formValues.importe,
			token: context.token,
			modificado
		}
        const res = await req.post('orden_trabajo/editar', data);
		if(res.updated) {
			cogoToast.success('O.C./S.I. actualizado', { position: 'top-right', heading: 'Actualizado!' });
			this.closeModal('editOrdenTrabajo');
			this.props.reload();
		} else {
			cogoToast.error(res.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
        this.setState({loading: false});
    }

	setPage(page) {
		this.setState({page});
	}

	filtrar(elementos, filtros) {
		let result = [];
		try {
			if (Array.isArray(elementos) && Array.isArray(filtros)) {
				filtros.forEach(campo => {
					elementos.forEach(elemento => {
						let value = elemento[campo];
						value = value.toLowerCase();
						if (value.indexOf(this.state.filtro) !== -1) {
							result.push(elemento);
						}
					})
				});
				//Evitar duplicados
				result = result.filter(function (element, pos, array) {
					return array.map((mapItem) => {
						return mapItem;
					}).indexOf(element) === pos;
				});
				return result;
			} else {
				return elementos;
			}
		} catch(error) {
			alert(error.message);
			return elementos;
		}
	}

    render() {
		let ordenesTrabajo = [];
		let pages = 1;

        if(Array.isArray(this.props.ordenesTrabajo)) {
			ordenesTrabajo = this.filtrar(this.props.ordenesTrabajo, ['concepto']);
			pages = Math.ceil(ordenesTrabajo.length / 10);
			ordenesTrabajo = ordenesTrabajo.slice(((this.state.page - 1) * 10), (this.state.page * 10));
		}

        return (
            <div className="row table-container">
                <div className="column full">
                    <Controles
						setFiltro 	= 	{(filtro) => this.setState({filtro})}
						title		=	"Lista de S.I./O.T."
					/>
                    <div className="white-space-16"></div>
                    <table className="tabla-presupuestos">
						<thead>
							<tr>
								<th className="text-left">Folio P.</th>
								<th className="text-left">Titulo</th>
								<th className="text-center">Fecha</th>
								<th className="text-right">Total</th>
								<th className="text-center"></th>
							</tr>
						</thead>
						<tbody>
                        {
							this.props.loading?
								<tr>
									<td colSpan="5">
										<div className="row justify-center full">
											<i className="fas fa-sync fa-spin"></i>
										</div>
									</td>
								</tr> :
							
								ordenesTrabajo.map((ot, key) => {
								return(
									<tr key={key}>
										<td className="text-left">
											{ot.folio}
										</td>
										<td className="text-left">
											{ot.concepto}
										</td>
										<td className="text-center">
											{moment(ot.fecha).format('DD/MM/YYYY')}
										</td>
										<td className="text-right">
											{ot.importe}
										</td>
										<td className="text-center">
											<button className="btn-table-blue" onClick={this.openModal.bind(this,'editOrdenTrabajo', ot)} data-title="Editar O.C./S.I.">
												<i className="fas fa-edit"></i>
											</button>
										</td>
									</tr>
								)}
							)
                        }
						</tbody>
                    </table>
					<Paginador setPage={this.setPage.bind(this)} pages={pages}/>
                </div>
                <DinamicModal 
					idModal					=	{'editOrdenTrabajo'}
					sizeModalContainer		=	{'normal'}
					title					=	{`Editar ${this.state.orden_trabajo.concepto}`} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
                    loadingSave				=	{this.state.loading}
                    success					=	{this.editarOrdenTrabajo.bind(this)} 
                    closeModal              =   {this.clearData.bind(this)}
					>
                        <OrdenTrabajoForm
							values 			= 	{this.state.orden_trabajo}
							getValues		=	{(values) => this.formValues = values}
							presupuestos	=	{this.props.presupuestos}/>
                </DinamicModal>
            </div>
        )
    }
}

export default Consumer(TablaPresupuestos);