import React from 'react';
import img from '../images/loginBG.png';
import CardLogin from "../components/loginCard";
import { Consumer } from '../context';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

	componentDidMount() {
		this.checkToken();
	}

	async checkToken() {
		const { context } = this.props;
		if (context) {
			const { token } = await context.loadUser();
			if ( token ) {
				this.props.history.push('/home');
			}
		}
	}

    render() {
        return (
			<>
				<div className="row full login-view">
					<div className="column logo-column full">
						<div className="overlay-login"></div>
					</div>
					<div className="column full form-column justify-center align-center">
						<CardLogin></CardLogin>
					</div>
				</div>
			</>
        )
    }
}

export default withRouter(Consumer(Login));