import React from 'react';
import DinamicModal from "../dinamicModal/dinamicModal";
import ClienteForm from '../clientes/form';
import Request from '../../api/apiService';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import { Consumer } from '../../context';
const req = new Request();

class TablaClientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 8,
            loading: false,
            clientes: [],
            cliente: {}
        }
    }

    openModal(idModal, item) {
		this.setState({cliente: item});
        const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }

	closeModal(idModal) {
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

    async editClient(event) {
        this.setState({loading: true, message: ''});
        event.preventDefault();
		const form = event.target;
		const { context } = this.props;
		const id_usuario = await context.getId(context.token);
		const { cliente, id_modulo } = this.state;
		const modificado = Array.isArray(cliente.modificado) ? cliente.modificado : [];
		modificado.push({id_usuario, fecha: moment().format('YYYY-MM-DD HH:mm:ss')});
        const data = {
			id_modulo: id_modulo,
			id_cliente: cliente.id_cliente,
			nombre: form.nombre.value,
			empresa: form.empresa.value,
			cargo: form.cargo.value
        }
        const res = await req.post('cliente/editar', data);
		if(res.updated) {
			cogoToast.success('Cliente actualizado', { position: 'top-right', heading: 'AGREGADO!' });
			this.closeModal('editarCliente');
			this.props.reload();
		} else {
			cogoToast.error(res.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
        this.setState({loading: false});
    }

    render() {
        let clientes = [];
        if(Array.isArray(this.props.clientes)) {
            clientes = this.props.clientes
        }

        return (
            <div className="row table-container">
                <div className="column full">
                    <div className="row full header-table">
                        <div className="column full">
                            <div className="row">
                                <i className="fas fa-address-card"></i>
                                <span className="color-white weight-semibold">Lista de clientes</span>
                            </div>
                        </div>
                        <div className="row full justify-end">
                            <div className="row input-search">
                                <input className="" type="text" id="usuario" name="usuario" placeholder="Buscar ..." maxLength="256" required></input>
                                <i className="fas fa-search font-small icon-search"></i>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <table>
                        <tr>
                            <th className="text-left">Nombre</th>
                            <th className="text-left">Empresa</th>
                            <th className="text-left">Cargo</th>
                            <th className="text-right"></th>
                        </tr>
                    {
                        this.props.loading?
                            <tr>
                                <td colSpan="4">
                                    <div className="row justify-center">
                                        <i className="fas fa-sync fa-spin"></i>
                                    </div>
                                </td>
                            </tr> :
                        
                            clientes.map(cliente => {
                            return(
                                <tr  key={cliente.id_cliente}> 
                                    <td className="text-left">{cliente.nombre}</td>
                                    <td className="text-left">{cliente.empresa}</td>
                                    <td className="text-left">{cliente.cargo}</td>
                                    <td className="text-right">
                                        <button className="btn-table-blue" onClick={this.openModal.bind(this,'editarCliente', cliente)}>
                                            <i className="fas fa-user-edit"></i>
                                        </button>
                                    </td>
                                </tr>
                            )}
                        )
					}
                    </table>
                </div>
                <DinamicModal 
					idModal					=	{'editarCliente'}
					sizeModalContainer		=	{'small'}
					title					=	{'Editar cliente'} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
                    loadingSave				=	{this.state.loading}
                    success					=	{this.editClient.bind(this)} 
					>
                        <ClienteForm values = {this.state.cliente}/>
                    </DinamicModal>
            </div>
        )
    }
}

export default Consumer(TablaClientes) ;