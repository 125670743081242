import React from 'react';
import { IMG_URL } from '../../api/url';

function imgError(img) {
	img.target.src =  "/images/user-back.png";
}

function MaestroCard({maestro}) {
    return (
		<div className="row-responsive full maestro-card">
			<div className="column profile-image">
				<img src={`${IMG_URL}imagen?path=${maestro.imagen}`} onError={imgError.bind(this)}/>
			</div>
			<div className="column full justify-between">
				<div className="row full justify-between align-start">
					<h2>{maestro.nombre}</h2>
				</div>
				<p className="column full">
					<b>Formación:</b>
					{maestro.formacion}
				</p>
				<p className="column full">
					<b>Especialidades:</b>
					{maestro.especialidades}
				</p>
			</div>
		</div>
    )
}

export default MaestroCard;