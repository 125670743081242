import Request from '../../api/apiService';
const req = new Request();

export async function loadModulos() {
	let { modulos } = await req.get('modulos/get');
	if (!Array.isArray(modulos)) {
		modulos = [];
	}
	this.setState({modulos});
	return modulos;
}