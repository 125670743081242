import React from "react";
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import Request from "../../api/apiService";
import {Link} from "react-router-dom";
const req = new Request();

export async function loginSuccess(event) {
	localStorage.setItem("mrd_user", JSON.stringify(event));
	this.setState({auth: true, token: event.token, facebookID: event.facebookID});

	// Revisar si tiene telefono
	const {token} = await loadUser();
	revisarTelefono(token);
}

export function logOut() {
	window.localStorage.setItem("mrd_user", '{"auth": false}');
	localStorage.removeItem("mrd_modulos");
	this.setState({auth: false, token: undefined});
	window.location = "/";
}

export async function getId(token) {
	if (token) {
		const res = await req.post("usuario/get/id", {token});
		if (res.id_usuario) {
			return res.id_usuario;
		}
	}
}

export async function getPermisos(token) {
	if (token) {
		const res = await req.post("usuario/permisos", {token});
		if (res.token && res.permisos) {
			this.setState({permisos: res.permisos, token: res.token});
			let session = JSON.parse(window.localStorage.getItem("mrd_user"));
			localStorage.setItem("mrd_user", JSON.stringify({...session, token: res.token}));
			return res.permisos;
		}
		return [];
	} else {
		return [];
	}
}

export async function loadUser() {
	let session = JSON.parse(window.localStorage.getItem("mrd_user")) || {};

	const data = {
		token: session.token,
	};
	const user = await req.post("decode", data);

	return user;
}

export async function revisarTelefono(token) {
	if (token) {
		if (token.id_usuario) {
			const res = await req.post("usuario/tiene_telefono", {id_usuario: token.id_usuario});
			if (!res.error) {
				if (!res.telefono) {
					const modal = document.getElementById("modal-no-phone");
					if (modal) {
						modal.classList.remove("hide-dinamic-modal");
						modal.classList.add("show-dinamic-modal");
					}
					return false;
				}
				return true;
			}
		}
	}
}

export function modalTelefono() {
	function cerrarModal() {
		const modal = document.getElementById("modal-no-phone");
		if (modal) {
			modal.classList.add("hide-dinamic-modal");
			modal.classList.remove("show-dinamic-modal");
		}
	}

	return (
		<DinamicModal idModal="modal-no-phone" sizeModalContainer={"normal"} title={"Tus datos no están completos"}>
			<p style={{color: "white"}}>
				Por favor ingresa tus datos completos en
				<Link to="/perfil" onClick={() => cerrarModal()}>
					<b style={{color: "#0C5EBB"}}>&nbsp; Perfil</b>
				</Link>,
				 esto nos ayudará a organizar las sesiones.
			</p>
		</DinamicModal>
	);
}
