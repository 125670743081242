import React from 'react';
import { loginSuccess, logOut, loadUser, getPermisos, getId, revisarTelefono, modalTelefono } from './Actions/users';
import { loadModulos } from './Actions/modulos';
import { getPerfiles } from './Actions/perfiles';
import { loadSesionesMaterias,loadSesionesHorario,loadSesionesProfesores } from './Actions/sesiones';
import Request from '../api/apiService';
const req = new Request();

let state = window.localStorage.getItem('mrd_user');
if(state) {
    state = JSON.parse(state);
}

const Context = React.createContext('app');

export class Provider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: this.checkAuth(),
            token: this.checkToken(),
            getPermisos: getPermisos.bind(this),
			loginSuccess: loginSuccess.bind(this),
            logout: logOut.bind(this),
			loadUser: loadUser.bind(this),
			modulos: [],
            loadModulos: loadModulos.bind(this),
            perfiles: [],
			getPerfiles: getPerfiles.bind(this),
			getId: getId.bind(this),
			imagen: '',
			nombre: '',
            userInit: this.userInit.bind(this),
            loadSesionesProfesores: loadSesionesProfesores.bind(this),
            loadSesionesHorario: loadSesionesHorario.bind(this),
            loadSesionesMaterias: loadSesionesMaterias.bind(this),
            revisarTelefono: revisarTelefono.bind(this),
            materias:[],
            profesores:[],
            horarios:[]
        }
    }

	async componentDidMount() {
        this.state.loadModulos();
        this.state.getPerfiles();

        const { token }  = await loadUser();
		
		this.revisarTelefono(token);
		
		if (token) {
			this.setState({imagen: token.imagen, nombre: token.nombre, telefono: token.telefono});
        }

	}

	async revisarTelefono(token) {
		if (token) {
			if (token.id_usuario) {
                const response = await revisarTelefono(token);
                return response;
			}
		}
	}

	async userInit() {
		const { token }  = await loadUser();
		this.setState({imagen: token.imagen, nombre: token.nombre});
	}

    checkAuth() {
        if (window.localStorage.getItem('mrd_user')) {
            const session = JSON.parse(window.localStorage.getItem('mrd_user'));
            return session.auth;
        }
    }

    checkToken() {
        if (window.localStorage.getItem('mrd_user')) {
            const session = JSON.parse(window.localStorage.getItem('mrd_user'));
            return session.token;
        }
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
				{modalTelefono()}
            </Context.Provider>
        )
    }
}

export function Consumer(Component) {
    return function WrapperComponent(props) {
        return (
            <Context.Consumer>
                {state => <Component {...props} context={state} />}
            </Context.Consumer>
        );
    };
}