import React, { useState } from 'react';

const Controles = (props) => {
	const [filtro, setFiltro] = useState('');

	function filtrar() {
		if (props.setFiltro) {
			props.setFiltro(filtro.toLowerCase());
		}
	}

	function filtroChange(event) {
		const text = event.target.value;
		if (text) {
			setFiltro(text);
		} else {
			setFiltro('');
			if (props.setFiltro) {
				props.setFiltro('');
			}
		}
	}

	return (
		<div className="row full header-table">
			<div className="column full">
				<div className="row">
					<i className="fas fa-th-list"></i>
					<span className="color-white weight-semibold">{props.title}</span>
				</div>
			</div>
			<div className="row full justify-end">
				<div className="row input-search">
					<input
						className=""
						type="text"
						id="usuario"
						name="usuario"
						placeholder="Buscar ..."
						maxLength="256"
						required
						autoComplete = "off"
						value = {filtro}
						onChange={filtroChange.bind(this)}/>
					<button className="icon-search" onClick={filtrar.bind(this)}>
						<i className="fas fa-search font-small"></i>
					</button>
				</div>
			</div>
		</div>
	)
}

export default Controles;