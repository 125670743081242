import React, {useState, useEffect} from "react";
import Calendar from "react-calendar";
import moment from "moment-timezone";
import {Consumer} from "../../context/index";

const SesionesForm = (props) => {
	const [prevProps, setPrevProps] = useState({});
	const [fechaSesion, setFechaSesion] = useState();
	const [materia, setMateria] = useState("");
	const [horario, setHorario] = useState("");
	const [comentario, setComentario] = useState("");
	const [nombre, setNombre] = useState("");
	const [readOnly, setReadOnly] = useState(false);
	const [horarios, setHorarios] = useState([]);
	const [materias, setMaterias] = useState([]);

	useEffect(() => {
		if (props.values !== prevProps.values) {
			if (props.values) {
				const {values} = props;

				if (values) {
					setMateria(values.materia);
					setHorario(values.horario);
					setComentario(values.comentarios);
					setFechaSesion(values.fecha);
					setNombre(values.nombre);
					setReadOnly(props.readOnly ? props.readOnly : false);
					console.log(values.fecha);
				} else {
					console.error("No se encontro un campo en el formulario");
				}
			}
			setPrevProps(props);
		}
	});

	useEffect(() => {
		getCatalog();
	}, []);

	async function getCatalog() {
		let materias = await props.context.loadSesionesMaterias();
		let horarios = await props.context.loadSesionesHorario();
		setHorarios(horarios);
		setMaterias(materias);
	}

	const changeCalendar = (date) => {
		if (!readOnly) {
			setFechaSesion(date);
			props.updateState({fecha: date});
		}
	};

	return (
		<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-medium ">
						<p> Nombre :</p>
					</div>
					<div className="column full">
						<input
							className="input input-modals"
							name="role"
							id="sesion_nombre"
							name="sesion_nombre"
							type="text"
							value={nombre}
							onChange={(event) => {
								setNombre(event.target.value);
							}}
							disabled={readOnly}
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium ">
						<p> Materia :</p>
					</div>
					<div className="column full">
						<select
							className="input input-modals"
							id="sesion_materia"
							name="sesion_materia"
							value={materia}
							onChange={(event) => {
								setMateria(event.value);
							}}
							disabled={readOnly}
						>
							<option value="" selected>
								Materias
							</option>
							{materias.map((mat, key) => {
								return (
									<option key={key} value={mat.materia}>
										{mat.materia}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium ">
						<p>Fecha :</p>
					</div>
					{readOnly ? (
						<input
							className="input input-modals"
							name="role"
							id="sesion_nombre"
							name="sesion_nombre"
							type="text"
							value={`${fechaSesion ? moment(fechaSesion[0]).format("DD/MM/YYYY") : null} - ${fechaSesion ? moment(fechaSesion[1]).format("DD/MM/YYYY") : null}`}
							disabled={readOnly}
						/>
					) : (
						<div className="column full justify-center align-center">
							<Calendar name="sesion_fecha" selectRange={true} returnValue={"range"} value={fechaSesion} allowPartialRange={true} onChange={changeCalendar} />
						</div>
					)}
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p>Horario :</p>
					</div>
					<div className="column full">
						<select
							className="input input-modals"
							name="role"
							id="sesion_horario"
							name="sesion_horario"
							value={horario}
							onChange={(event) => {
								setHorario(event.value);
							}}
							disabled={readOnly}
						>
							<option value="" selected>
								Horario
							</option>
							{horarios.map((hr, key) => {
								return (
									<option key={key} value={hr.horario}>
										{hr.horario}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium ">
						<p>Comentarios :</p>
					</div>
					<div className="column full">
						<textarea
							id="sesion_comentario"
							name="sesion_comentario"
							className="input text-modal"
							rows="2"
							value={comentario}
							onChange={(event) => {
								setComentario(event.value);
							}}
							disabled={readOnly}
						></textarea>
					</div>
				</div>

				<div className="white-space-32"></div>
			</div>
		</div>
	);
};

export default Consumer(SesionesForm);
