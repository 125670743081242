import React, { useState, useEffect } from 'react';
import { Consumer } from '../../context/index';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';

const OrdenTrabajoForm = (props) => {
	const [prevProps, setPrevProps] = useState({});
	const [state, setState] = useState({
		fecha:			'',
		concepto:		'',
		id_presupuesto:	'',
		importe:		0,
		presupuestos: 	[]
	});

    useEffect(() => {
        if (props.getValues) {
            props.getValues({
				fecha: state.fecha,
				concepto: state.concepto,
				importe: state.importe,
				id_presupuesto: state.id_presupuesto
			});
		}
		if (props.values !== prevProps.values) {
			if (props.values) {
				const { values } = props;
				setState({
					...state,
					fecha: moment(values.fecha).format('YYYY-MM-DD'),
					concepto: values.concepto,
					importe: values.importe,
					id_presupuesto: values.id_presupuesto
				})
			}
			setPrevProps(props);
		}
		if (Array.isArray(props.presupuestos) && props.presupuestos !== prevProps.presupuestos) {
			setState({...state, presupuestos: props.presupuestos});
			setPrevProps(props);
		}
    });

    return (
        <div className="full row align-center presupuesto-form">
            <div className="column full">
                <div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Fecha :</p>
                    </div>
                    <div className="column full">
                        <input
							value			=	{state.fecha}
							onChange		=	{(input) => setState({...state, fecha: input.target.value})}
                            type			=	"date" 
							name			=	"fecha"
							id				=	"orden_trabajo_fecha"
                            className		=	"input input-modals" 
                            required
							autoComplete    =   "off"
                        />
                    </div>
                </div>
                <div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Concepto :</p>
                    </div>
                    <div className="column full">
                        <input
							value			=	{state.concepto}
							onChange		=	{(input) => setState({...state, concepto: input.target.value})}
                            type			=	"text" 
							name			=	"concepto"
							id				=	"orden_trabajo_titulo"
                            className		=	"input input-modals-textarea" 
                            required				 
                            maxLength		=	"250"
							autoComplete    =   "off"
                        />
                    </div>
                </div>
                <div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Presupuesto :</p>
                    </div>
                    <div className="column full">
                        <select
							value			=	{state.id_presupuesto}
							onChange		=	{(input) => setState({...state, id_presupuesto: input.target.value})}
							name			=	"presupuesto"
							id				=	"orden_trabajo_presupuesto"
                            className		=	"input input-modals" 
                            required				 
							autoComplete    =   "off"
                        >
							<option value="">Selecciona Presupuesto</option>
							{state.presupuestos.map(p => <option key={p.id_presupuesto} value={p.id_presupuesto}>{p.folio}</option>)}
						</select>
                    </div>
                </div>
                <div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Importe :</p>
                    </div>
                    <div className="column full">
                        <input
							value			=	{state.importe}
							onChange		=	{(input) => setState({...state, importe: input.target.value})}
                            type			=	"number" 
							name			=	"importe"
							id				=	"orden_trabajo_importe"
                            className		=	"input input-modals" 
                            required
							autoComplete    =   "off"
                        />
                    </div>
                </div>
				<div className="white-space-32"></div>
            </div>
        </div>
    )
}

export default Consumer( OrdenTrabajoForm );