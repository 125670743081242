import React, { useState, useEffect } from 'react';

const CheckBox = (props) => {
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        if (props.checked === true) {
            setChecked(true);
        }
        if (props.checked === false) {
            setChecked(false);
        }
    });

    return (
        <label className="checkbox">
            <input type="checkbox" checked={checked} onChange={(event) => {
                if (props.onChange) {
                    props.onChange(event.target.checked);
                }
                setChecked(event.target.checked)}
            }/>
            <span className="checkmark"></span>
        </label>
    )
}

export default CheckBox;