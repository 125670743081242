import React, { useState, useEffect } from 'react';
import { Consumer } from '../../context/index';

const ClienteForm = (props) => {
	const [prevProps, setPrevProps] = useState({});
	const { context } = props;

	useEffect(() => {
		if (props.values !== prevProps.values) {
			if (props.values) {
				const { values } = props;
				const nombre = document.getElementById('cliente_nombre');
				const empresa = document.getElementById('cliente_empresa');
				const cargo = document.getElementById('cliente_cargo');

				if (nombre, empresa, cargo) {
					nombre.value = values.nombre;
					empresa.value = values.empresa;
					cargo.value = values.cargo;
				} else {
					console.error('No se encontro un campo en el formulario');
				}
			}
			setPrevProps(props);
		}
	});

    return (
        <div className="full row align-center">
            <div className="column full">
                <div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Nombre :</p>
                    </div>
                    <div className="column full">
                        <input 
                            type			=	"text" 
							name			=	"nombre"
							id				=	"cliente_nombre" 
                            className		=	"input input-modals" 
                            required				 
                            maxLength		=	"500"
                            autoComplete    =   "off"
                        />
                    </div>
                </div>
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p>Empresa :</p>
                    </div>
                    <div className="column full">
                        <input 
                            type			=	"text" 
							name			=	"empresa"
							id				=	"cliente_empresa" 
                            className		=	"input input-modals" 
                            required				 
                            maxLength		=	"500"
                            autoComplete    =   "off"
                        />
                    </div>
                    
                </div>
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
                    <div className="column label-medium">
                        <p>Cargo :</p>
                    </div>
                    <div className="column full">
                        <input 
                            type			=	"text" 
							name			=	"cargo"
							id				=	"cliente_cargo" 
                            className		=	"input input-modals" 
                            required				 
                            maxLength		=	"100"
                            autoComplete    =   "off"
                        />
                    </div>                
                </div>                
                <div className="white-space-32"></div>
            </div>
        </div>
    )
}

export default Consumer( ClienteForm );