import React from 'react';
import Sidebar from "../components/sideBar";
import TableUsuarios from "../components/tables/presupuestosTabla";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import { Consumer } from '../context';
// TOASR ALERTS & LOADING SPINERS
import cogoToast from 'cogo-toast';
import Request from '../api/apiService';
const req = new Request();
const Spinner = require('react-spinkit');

class Pagos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            presupuestos: []
        }
    }

    componentDidMount() {
	    this.loadUser();
	}
    
    openModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }

	async loadUser() {
		this.setState({loading: true, message: ''});
		let tokenData = JSON.parse(window.localStorage.getItem("inv4mb_user"));

		const data = {
            token: tokenData.user
        }
        const user = await req.post('decode', data);
        
        this.setState({user});

		this.loadClients(user)
	}

    async loadClients(user) {
        console.log("Data",user.token.id_branch);

        const data = {
            id_branch: user.token.id_branch
        }

        const res = await req.post('presupuestos/get', data);
        this.setState({message: res.message});
        if(res) {
            this.setState({ presupuestos: res.presupuestos });
        } else {
            cogoToast.error('No se pudieron obtener los presupuestos.', { position: 'top-right', heading: 'OOPS!' });
        }
        this.setState({loading: false});
    }

    reload() {
        this.loadUser();
    }

    async addNewUser(event) {
        this.setState({loading: true, message: ''});
        event.preventDefault();
        const form = event.target;
        const data = {
            p_name              :   form.name.value,
            p_lastname          :   form.lastname.value,
            p_email             :   form.email.value,
            p_user              :   form.user.value,
            p_pass              :   form.pass.value,
            p_id_role           :   form.role.value,

            id_branch           :   this.state.user.token.id_branch,
        }

        console.log("new usuario ->", data);
        const res = await req.post('usuarios/create', data);
        console.log("RESPUESTA usuarios ->", res);
        if(res.created == true) {
            this.reload();
            const modal = document.getElementById('newUser');
            if(modal) {
                modal.classList.remove('show-dinamic-modal');
                modal.classList.add('hide-dinamic-modal');
            }
        }
       // this.setState({message: res.message});
        this.setState({loading: false});
    }

    render() {
        let presupuestos = [];
		if(this.state.presupuestos) {
			presupuestos = this.state.presupuestos;
			//console.log('DATEEEE', products)
		}
        return (
            
            <div className="row full inventario-view">
                <Sidebar view="usuarios"></Sidebar>
                <div className="column full align-center">
                    <div className="container column">

                        <div className="row full header-options align-center">
                            <div className="column full title">
                                <h1 className="weight-light color-white">Pagos</h1>
                            </div>
                            <div className="column full btns-options align-end">
                                <div className="row">
                                
                                <button className="btn-options btn-orange-plus" onClick={this.openModal.bind(this,'newUser')} >
                                    <i className="fas fa-plus font-regular"></i>
                                </button>
                                </div>
                            </div>
                        </div>
                        <TableUsuarios presupuestos={presupuestos} loading={this.state.loading} reload={this.reload.bind(this)}></TableUsuarios>
                    </div>
                </div>
                <DinamicModal 
					idModal					=	{'newUser'}
					sizeModalContainer		=	{'normal'}
					title					=	{'Agregar nuevo cliente'} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
                    btnTextCancel			=	{"CANCELAR"}
                    success                 =   {this.addNewUser.bind(this)}
					loadingSave				=	{this.state.loading}
					>
                        {this.renderNewPresupuesto()}
                </DinamicModal>
            </div>
        )
    }

    renderNewPresupuesto() {
		return(
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-medium ">
						<p> Nombre :</p>
					</div>
					<div className="column full">
						<input 
							type			=	"text" 
							name			=	"name" 
                            className		=	"input input-modals" 
							required				 
							maxLength		=	"120"
						/>
					</div>
				</div>
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
					<div className="column label-medium ">
						<p>Apellidos :</p>
					</div>
					<div className="column full">
						<input 
							type			=	"text" 
							name			=	"lastname" 
                            className		=	"input input-modals" 
							required				 
							maxLength		=	"240"
						/>
					</div>
                    
				</div>
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
					<div className="column label-medium ">
						<p>Correo :</p>
					</div>
					<div className="column full">
						<input 
							type			=	"text" 
							name			=	"email" 
                            className		=	"input input-modals" 
							required				 
							maxLength		=	"240"
						/>
					</div>
                    
				</div>
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
					<div className="column label-normal">
						<p>Usuario :</p>
					</div>
					<div className="column column-small">
						<input 
							type			=	"text" 
							name			=	"user" 
                            className		=	"input input-modals" 
							required				 
							maxLength		=	"120"
						/>
					</div>
                    <div className="column label-midle">
						<p> Contraseña :</p>
					</div>
					<div className="column column-small">
						<input 
							type			=	"text" 
							name			=	"pass" 
                            className		=	"input input-modals" 
							required				 
							maxLength		=	"120"
						/>
					</div>
                   
				</div>
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
					<div className="column label-medium ">
						<p>Rol :</p>
					</div>
					<div className="column full">
                    <select className="input input-modals" name="role"> 
                        <option value="1">Admin</option>
                        <option value="2">Ventas</option>
                    </select>
					</div>
                    
				</div>
                
                <div className="white-space-32"></div>
		
            </div>
		</div>

		)
    }
}

export default Consumer(Pagos);
