
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider, Consumer } from './context';
// contenido
import Login from './pages/login';
import Home from "./pages/home";
import Usuarios from "./pages/usuarios";
import Perfiles from "./pages/perfiles";
import Sesiones from "./pages/sesiones";
import Alumnos from "./pages/alumnos";
import Historial from "./pages/historial";
import Perfil from "./pages/perfil";
//Modulos
import Presupuestos from "./modules/presupuestos";
import Clientes from "./modules/clientes";
import OrdenesCompra from "./modules/ordenesCompra";
import OrdenesTrabajo from "./modules/ordenesTrabajo";
import Facturas from "./modules/facturas";
import Pagos from "./modules/pagos";

const PrivateRoute = Consumer (
	({ component: Component, context, ...rest }) => {
		return (
			<Route
				{...rest}
				render={
					props => {
						if (context.auth) {
							return <Component {...props} />;
						} else {
							return (
								<Redirect to={{ pathname: "/", state: { from: props.location } }} />
							);
						}
					}
				}
			/>
		)
	}
);

class App extends React.Component {
	constructor(props) {
		super(props);
		this.facebookSDK();
	}

	facebookSDK() {
		window.fbAsyncInit = function() {
			window.FB.init({
				appId      : process.env.NODE_ENV === 'production'? '1186082381776050' : '619903178695687',
				cookie     : true,
				xfbml      : true,
				version    : 'v8.0'
			});
			
			window.FB.AppEvents.logPageView();
		};

		(function(d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {return;}
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/es_MX/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}

	render() {
		return (
			<BrowserRouter>
				<Provider>
					<div className="main">
						< Switch>
							<Route path="/" exact component={ Login }></Route>
							<PrivateRoute path="/home" exact component={Home}/>
							<PrivateRoute path="/usuarios" exact component={Usuarios}/>
							<PrivateRoute path="/perfiles" exact component={Perfiles}/>
							<PrivateRoute path="/perfil" exact component={Perfil}/>
							<PrivateRoute path="/presupuestos" exact component={Presupuestos}/>
							<PrivateRoute path="/clientes" exact component={Clientes}/>
							<PrivateRoute path="/ordenestrabajo" exact component={OrdenesTrabajo}/>
							<PrivateRoute path="/ordenescompra" exact component={OrdenesCompra}/>
							<PrivateRoute path="/facturas" exact component={Facturas}/>
							<PrivateRoute path="/pagos" exact component={Pagos}/>
							<PrivateRoute path="/sesiones" exact component={Sesiones}/>
							<PrivateRoute path="/sesiones/alumnos" exact component={Alumnos}/>
							<PrivateRoute path="/sesiones/historial" exact component={Historial}/>
						</Switch>
					</div>
				</Provider>
			</BrowserRouter>
		);
	}
}

export default App;