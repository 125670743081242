export let API_URL;
export let FILES_URL;
export let PDF_URL;
export let IMG_URL;

if(process.env.NODE_ENV !== 'production') {
	API_URL 	= 	"http://localhost:8081/";
	FILES_URL 	= 	"http://localhost:8081/";
	PDF_URL 	= 	"http://localhost:8081/";
	IMG_URL		=	"http://localhost:8081/";
} else {
	API_URL 	= 	"https://apipolitareas.mrdserver.com/";
	FILES_URL 	= 	"https://apipolitareas.mrdserver.com/";
	PDF_URL 	= 	"https://apipolitareas.herokuapp.com/";
	IMG_URL		=	"https://apipolitareas.mrdserver.com/";
}
