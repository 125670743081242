import React, { useState, useEffect } from 'react';
import Sidebar from '../components/sideBar';
import Request from '../api/apiService';
import cogoToast from 'cogo-toast';
import MaestroCard from '../components/dashboard/maestro-card';

const request = new Request();

async function loadMaestros() {
	const response = await request.get('dashboard/maestros/obtener');
	if (response.error) {
		cogoToast.error(response.message || 'Error interno', { position: 'top-right' });
		return null;
	}
    return response;
}

function Home() {
	const [maestros, setMaestros] = useState([]);

	useEffect(() => {
		async function load() {
			const response = await loadMaestros();
			if (response.maestros) {
				setMaestros(response.maestros)
			}
		}

		load();
	}, []);

    return (
        <div className="row full view dashboard">
			<Sidebar view="sesiones"></Sidebar>
			<div className="column full align-center">
				<div className="container column">
					<div className="row full header-options align-center">
						<div className="column full title">
							<h1 className="weight-light color-white">Nuestros Maestros</h1>
						</div>
					</div>
					<div className="column full">
						{maestros.map((maestro, key) => (
							<MaestroCard key={key} maestro={maestro}/>
						))}
					</div>
					<div className="white-space-32"></div>
				</div>
			</div>
        </div>
    )
}

export default Home;
