import React, { useState, useEffect } from 'react';
import { Consumer } from '../../context/index';

const UsuarioForm = (props) => {
	const [prevProps, setPrevProps] = useState({});
	const { context } = props;

	useEffect(() => {
		if (props.values !== prevProps.values) {
			if (props.values) {
				const { values } = props;
				const nombre = document.getElementById('usuario_nombre');
				const correo = document.getElementById('usuario_correo');
				const usuario = document.getElementById('usuario_usuario');
				const password = document.getElementById('usuario_password');
				const perfil = document.getElementById('usuario_perfil');

				if (nombre, correo, usuario, password, perfil) {
					nombre.value = values.nombre;
					correo.value = values.correo;
					usuario.value = values.usuario;
					password.required = false;
					password.value = '';
					perfil.value = values.id_perfil;
				} else {
					console.error('No se encontro un campo en el formulario');
				}
			}
			setPrevProps(props);
		}
	});

    return (
        <div className="full row align-center">
            <div className="column full">
                <div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Nombre :</p>
                    </div>
                    <div className="column full">
                        <input 
                            type			=	"text" 
							name			=	"name"
							id				=	"usuario_nombre" 
                            className		=	"input input-modals" 
                            required				 
                            maxLength		=	"120"
                            autoComplete    =   "off"
                        />
                    </div>
                </div>
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p>Correo :</p>
                    </div>
                    <div className="column full">
                        <input 
                            type			=	"email" 
							name			=	"email"
							id				=	"usuario_correo" 
                            className		=	"input input-modals" 
                            required				 
                            maxLength		=	"240"
                            autoComplete    =   "off"
                        />
                    </div>
                    
                </div>
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
                    <div className="column label-normal">
                        <p>Usuario :</p>
                    </div>
                    <div className="column column-small">
                        <input 
                            type			=	"text" 
							name			=	"user"
							id				=	"usuario_usuario" 
                            className		=	"input input-modals" 
                            required				 
                            maxLength		=	"120"
                            autoComplete    =   "off"
                        />
                    </div>
                    <div className="column label-midle">
                        <p> Contraseña :</p>
                    </div>
                    <div className="column column-small">
                        <input 
                            type			=	"password" 
							name			=	"pass"
							id				=	"usuario_password"
                            className		=	"input input-modals" 
                            required				 
                            maxLength		=	"120"
                            autoComplete    =   "off"
                        />
                    </div>
                
                </div>
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p>Perfil :</p>
                    </div>
                    <div className="column full">
						<select
							className	=	"input input-modals"
							name		=	"role"
							id			=	"usuario_perfil"
						> 
							{context.perfiles.map(p => <option key={p.id_perfil} value={p.id_perfil}>{p.perfil}</option>)}
						</select>
                    </div>
                    
                </div>
                
                <div className="white-space-32"></div>
        
            </div>
        </div>
    )
}

export default Consumer( UsuarioForm );