import React, { useState, useEffect } from 'react';
import { Consumer } from '../../context/index';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';

const PresupuestoForm = (props) => {
	const [prevProps, setPrevProps] = useState({});
	const [state, setState] = useState({
		fecha:		'',
		titulo:		'',
		id_cliente:	'',
		concepto: 	'',
		unidad:		'',
		cantidad:	1,
		precio:		0,
		conceptos:	[],
		clientes: 	[]
	});

    useEffect(() => {
        const { context } = props;
        if (props.getValues) {
            props.getValues({
				titulo: state.titulo,
				conceptos: state.conceptos,
				fecha: state.fecha,
				id_cliente: state.id_cliente
			});
		}
		if (props.values !== prevProps.values) {
			if (props.values) {
				const { values } = props;
				setState({
					...state,
					fecha: moment(values.fecha).format('YYYY-MM-DD'),
					titulo: values.titulo,
					id_cliente: values.id_cliente,
					conceptos: values.conceptos || []
				})
			}
			setPrevProps(props);
		}
		if (Array.isArray(props.clientes) && props.clientes !== prevProps.clientes) {
			setState({...state,	clientes: props.clientes});
			setPrevProps(props);
		}
    });

	function getId(array) {
		if (array.length > 0) {
			const arr = array.map(a => a.id_concepto)
			const max = Math.max( ...arr );
			return max + 1;
		} else {
			return 1
		}
	}

	function addConcepto() {
		const values = state;
		if (!values.concepto) {
			cogoToast.warn('Concepto inválido.', {position: "top-right"});
			return;
		}
		if (!values.cantidad) {
			cogoToast.warn('Cantidad inválida.', {position: "top-right"});
			return;
		}
		if (!values.unidad) {
			cogoToast.warn('Unidad inválida.', {position: "top-right"});
			return;
		}
		if (isNaN(values.precio)) {
			cogoToast.warn('Precio inválido.', {position: "top-right"});
			return;
		}
		const newConceptos = state.conceptos;
		newConceptos.push({
			id_concepto: getId(newConceptos),
			concepto: values.concepto,
			cantidad: values.cantidad,
			unidad: values.unidad,
			precio: values.precio
		});
		setState({
			...state,
			concepto: '',
			cantidad: 1,
			unidad: '',
			precio: 0,
			conceptos: newConceptos
		});
		cogoToast.success('Concepto agregado.', {position: "top-right"});
	}

	function removeConcepto(id) {
		const newConceptos = state.conceptos.filter(c => c.id_concepto !== id)
		setState({...state, conceptos: newConceptos});
		cogoToast.success('Concepto eliminado.', {position: "top-right"});
	}

    return (
        <div className="full row align-center presupuesto-form">
            <div className="column full">
                <div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Fecha :</p>
                    </div>
                    <div className="column full">
                        <input
							value			=	{state.fecha}
							onChange		=	{(input) => setState({...state, fecha: input.target.value})}
                            type			=	"date" 
							name			=	"fecha"
							id				=	"presupuesto_fecha"
                            className		=	"input input-modals" 
                            required
							autoComplete    =   "off"
                        />
                    </div>
                </div>
                <div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Título :</p>
                    </div>
                    <div className="column full">
                        <textarea
							value			=	{state.titulo}
							onChange		=	{(input) => setState({...state, titulo: input.target.value})}
                            type			=	"text" 
							name			=	"titulo"
							id				=	"presupuesto_titulo"
                            className		=	"input input-modals-textarea" 
                            required				 
                            maxLength		=	"500"
							autoComplete    =   "off"
							rows			= 	"2"
                        />
                    </div>
                </div>
                <div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Persona :</p>
                    </div>
                    <div className="column full">
                        <select
							value			=	{state.id_cliente}
							onChange		=	{(input) => setState({...state, id_cliente: input.target.value})}
							name			=	"cliente"
							id				=	"presupuesto_cliente"
                            className		=	"input input-modals" 
                            required				 
							autoComplete    =   "off"
                        >
							<option value="">Selecciona Cliente</option>
							{state.clientes.map(c => <option key={c.id_cliente} value={c.id_cliente}>{c.nombre}</option>)}
						</select>
                    </div>
                </div>
                <div className="white-space-16"></div>
				<div className="input-form-content column full align-start">
					<div className="row justify-center full">
						<div className="column label-medium">
                        	<p> Agregar concepto </p>
						</div>
                    </div>
					<div className="white-space-8"></div>
					<div className="row full">
						<div className="column full">
							<div className="row full">
								<div className="column label-medium ">
									<p> Concepto :</p>
								</div>
								<textarea
									value			=	{state.concepto}
									onChange		=	{(input) => setState({...state, concepto: input.target.value})}
									type			=	"text" 
									name			=	"concepto"
									id				=	"presupuesto_concepto"
									className		=	"input input-modals-textarea"			 
									//maxLength		=	"500"
									autoComplete    =   "off"
									placeholder		= 	"concepto"
									rows			=	"2"
								/>
							</div>
							<div className="white-space-8"></div>
							<div className="row full">
								<div className="column full">
									<div className="column label-medium ">
										<p> Cantidad :</p>
									</div>
									<input
										value			=	{state.cantidad}
										onChange		=	{(input) => setState({...state, cantidad: input.target.value})}
										type			=	"number" 
										name			=	"cantidad"
										id				=	"presupuesto_cantidad"
										className		=	"input input-modals" 			 
										min				=	"1"
										autoComplete    =   "off"
										placeholder		= 	"cantidad"
									/>
								</div>
								&nbsp;
								<div className="column full">
									<div className="column label-medium ">
										<p> Unidad :</p>
									</div>
									<select
										value			=	{state.unidad}
										onChange		=	{(input) => setState({...state, unidad: input.target.value})}
										type			=	"text" 
										name			=	"unidad"
										id				=	"presupuesto_unidad"
										className		=	"input input-modals" 			 
										autoComplete    =   "off"
									>
										<option value="">Unidad</option>
										<option value="pza">Pieza</option>
										<option value="m">Metro</option>
									</select>
								</div>
								&nbsp;
								<div className="column full">
									<div className="column label-medium ">
										<p> Precio :</p>
									</div>
									<input
										value			=	{state.precio}
										onChange		=	{(input) => setState({...state, precio: input.target.value})}
										type			=	"number" 
										name			=	"precio"
										id				=	"presupuesto_precio"
										className		=	"input input-modals" 			 
										min				=	"0.00"
										autoComplete    =   "off"
										placeholder		= 	"precio"
									/>
								</div>
								&nbsp;
								<div className="column full justify-end">
									<button onClick={addConcepto.bind(this)} type="button" className="btn-options btn-orange-plus">
										Agregar
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="table-form-content row full align-center">
						<table className="tabla-permisos">
							<thead>
								<tr>
									<th className="text-left" style={{width: '50%'}}>
										Concepto
									</th>
									<th className="text-center" style={{width: '20%'}}>
										Cantidad
									</th>
									<th className="text-center" style={{width: '20%'}}>
										Unidad
									</th>
									<th className="text-right" style={{width: '20%'}}>
										Precio
									</th>
									<th className="text-right" style={{width: '20%'}}>
										Total
									</th>
									<th className="text-center" style={{width: '20%'}}>
									</th>
								</tr>
							</thead>
							<tbody>
								{state.conceptos.map(c => 
									<tr key={c.id_concepto}>
										<td className="text-left" style={{width: '50%'}}>
											{c.concepto}
										</td>
										<td className="text-center" style={{width: '20%'}}>
											{c.cantidad}
										</td>
										<td className="text-center" style={{width: '20%'}}>
											{c.unidad}
										</td>
										<td className="text-right" style={{width: '20%'}}>
											{c.precio}
										</td>
										<td className="text-right" style={{width: '20%'}}>
											{c.cantidad * c.precio}
										</td>
										<td className="text-center" style={{width: '20%'}}>
											<button onClick={removeConcepto.bind(this, c.id_concepto)} type="button" className="btn-options btn-orange-plus">
												-
											</button>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
                <div className="white-space-32"></div>
            </div>
        </div>
    )
}

export default Consumer( PresupuestoForm );