import React from 'react';
import Sidebar from "../components/sideBar";
import TablePerfiles from "../components/tables/perfilesTabla";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import PerfilForm from "../components/perfiles/form";
import { Consumer } from '../context';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import Request from '../api/apiService';
const req = new Request();

class Usuarios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 2,
            loading: false,
            modulos: []
        }
        this.modulos = [];
    }

    componentDidMount() {
	    this.loadPerfiles();
	}
    
    openModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }

    closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

    async loadPerfiles() {
		this.setState({loading: true});
		const data = {
			id_modulo: this.state.id_modulo
		}
        const res = await req.get('perfiles/tabla', data);
        if(res.perfiles) {
            this.setState({perfiles: res.perfiles});
        } else {
			this.setState({perfiles: []});
			cogoToast.error(res.message || 'Error interno.', { position: 'top-right', heading: 'OOPS!' });
		}
        this.setState({loading: false});
    }

    reload() {
        this.loadPerfiles();
    }

    async addPerfil(event) {
        this.setState({loadingSave: true, message: ''});

        event.preventDefault();

        const form = event.target;
        const { context } = this.props;

        if (Array.isArray(this.modulos)) {
            const lectura = [], escritura = [];
            this.modulos.forEach(m => {
                if (m.lectura) {
                    lectura.push(m.id_modulo);
                }
                if (m.escritura) {
                    escritura.push(m.id_modulo);
                }
            });
            const permisos = {lectura, escritura};
            const data = {
				id_modulo: this.state.id_modulo,
                nombre: form.name.value,
                permisos,
                token: context.token,
                fecha: moment().format('YYYY-MM-DD HH:mm:ss')
            }
            const res = await req.post('perfil/crear', data);
            if(res.created) {
                cogoToast.success('Nuevo Perfil Agregado', { position: 'top-right', heading: 'AGREGADO!' });
                this.closeModal('nuevoPerfil');
                this.reload();
            } else {
				cogoToast.error(res.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
				this.setState({message: data.message, loadingSave: false});
			}
        }
        this.setState({loadingSave: false});
    }

    render() {
        let perfiles = [];
		if(Array.isArray(this.state.perfiles)) {
			perfiles = this.state.perfiles;
		}
        return (
            
            <div className="row full view">
                <Sidebar view="perfiles"></Sidebar>
                <div className="column full align-center">
                    <div className="container column">

                        <div className="row full header-options align-center">
                            <div className="column full title">
                                <h1 className="weight-light color-white">Perfiles</h1>
                            </div>
                            <div className="column full btns-options align-end">
                                <div className="row">
                                
                                <button className="btn-options btn-orange-plus" onClick={this.openModal.bind(this,'nuevoPerfil')} >
                                    <i className="fas fa-plus font-regular"></i>
                                </button>
                                </div>
                            </div>
                        </div>
                        <TablePerfiles
                            perfiles	=	{perfiles}
                            loading		=	{this.state.loading}
                            reload		=	{this.reload.bind(this)}
							id_modulo 	=	{this.state.id_modulo} />
                    </div>
                </div>
                <DinamicModal 
					idModal					=	{'nuevoPerfil'}
					sizeModalContainer		=	{'normal'}
					title					=	{'Agregar nuevo perfil'} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
                    btnTextCancel			=	{"CANCELAR"}
                    success                 =   {this.addPerfil.bind(this)}
					loadingSave				=	{this.state.loadingSave}
					>
                        <PerfilForm
                            getValues={(modulosForm) => this.modulos = modulosForm}/>
                </DinamicModal>
            </div>
        )
    }
}

export default Consumer(Usuarios);