import Request from "../../api/apiService";
const req = new Request();

export async function loadSesionesMaterias() {
	let {materias} = await req.get("sesiones/lista/materia");
	this.setState({materias});
	return materias;
}
export async function loadSesionesHorario() {
	let {horarios} = await req.get("sesiones/lista/horario");
	this.setState({horarios});
	return horarios;
}
export async function loadSesionesProfesores() {
	let {profesores} = await req.get("usuario/lista/profesores");
	this.setState({profesores});
	return profesores;
}
