import React from 'react';
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import PresupuestoForm from "../../components/presupuestos/form";
import Request from '../../api/apiService';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import Paginador from '../paginador/paginador';
import { Consumer } from '../../context';
import { PDF_URL } from '../../api/url';
import Controles from './controles';
const req = new Request();

class TablaPresupuestos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 1,
            loading: false,
            presupuesto: {},
			presupuestos: [],
			page: 1,
			filtro: ''
		};
		this.formValues = {};
    }

    openModal(idModal, item) {
        const modal = document.getElementById(idModal);
        this.setState({presupuesto: item});
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }

	
	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

    clearData() {
        this.formValues = {};
    }

    async editarPresupuesto(event) {
        this.setState({loading: true, message: ''});
		event.preventDefault();
		const { context } = this.props;
		const id_usuario = await context.getId(context.token);
		const { presupuesto, id_modulo } = this.state;
		const modificado = Array.isArray(presupuesto.modificado) ? presupuesto.modificado : [];
		modificado.push({id_usuario, fecha: moment().format('YYYY-MM-DD HH:mm:ss')});
        const data = {
			id_modulo: id_modulo,
			id_presupuesto: presupuesto.id_presupuesto,
			titulo: this.formValues.titulo,
			id_cliente: this.formValues.id_cliente,
			conceptos: this.formValues.conceptos,
			token: context.token,
			fecha: this.formValues.fecha,
			modificado
		}
        const res = await req.post('presupuesto/editar', data);
		if(res.updated) {
			cogoToast.success('Presupuesto actualizado', { position: 'top-right', heading: 'Actualizado!' });
			this.closeModal('editPresupuesto');
			this.props.reload();
		} else {
			cogoToast.error(res.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
        this.setState({loading: false});
    }

	setPage(page) {
		this.setState({page});
	}

	filtrar(elementos, filtros) {
		let result = [];
		if (Array.isArray(elementos) && Array.isArray(filtros)) {
			filtros.forEach(campo => {
				elementos.forEach(elemento => {
					let value = elemento[campo];
					value = value.toLowerCase();
					if (value.indexOf(this.state.filtro) !== -1) {
						result.push(elemento);
					}
				})
			});
			//Evitar duplicados
			result = result.filter(function (element, pos, array) {
				return array.map((mapItem) => {
					return mapItem;
				}).indexOf(element) === pos;
			});
			return result;
		} else {
			return elementos;
		}
	}

    render() {
		let presupuestos = [];
		let pages = 1;

        if(Array.isArray(this.props.presupuestos)) {
			presupuestos = this.filtrar(this.props.presupuestos, ['folio']);
			pages = Math.ceil(presupuestos.length / 10);
			presupuestos = presupuestos.slice(((this.state.page - 1) * 10), (this.state.page * 10));
		}

        return (
            <div className="row table-container">
                <div className="column full">
                    <Controles
						setFiltro 	= 	{(filtro) => this.setState({filtro})}
						title		=	"Lista de Presupuestos"
					/>
                    <div className="white-space-16"></div>
                    <table className="tabla-presupuestos">
						<thead>
							<tr>
								<th className="text-left">Folio</th>
								<th className="text-left">Titulo</th>
								<th className="text-center">Fecha</th>
								<th className="text-right">Total</th>
								<th className="text-center"></th>
							</tr>
						</thead>
						<tbody>
                        {
							this.props.loading?
								<tr>
									<td colSpan="5">
										<div className="row justify-center full">
											<i className="fas fa-sync fa-spin"></i>
										</div>
									</td>
								</tr> :
							
								presupuestos.map((presupuesto, key) => {
								return(
									<tr key={key}> 
										<td className="text-left">
											{presupuesto.folio}
										</td>
										<td className="text-left">
											{presupuesto.titulo}
										</td>
										<td className="text-center">
											{moment(presupuesto.fecha).format('DD/MM/YYYY')}
										</td>
										<td className="text-right">
											{presupuesto.total}
										</td>
										<td className="text-center">
											<button className="btn-table-blue" onClick={this.openModal.bind(this,'editPresupuesto', presupuesto)} data-title="Editar Presupuesto">
												<i className="fas fa-edit"></i>
											</button>
											&nbsp;
											<button className="btn-table-blue" onClick={() => window.open(`${PDF_URL}presupuesto/preview/${presupuesto.id_presupuesto}/${presupuesto.folio}`,'_blank')} data-title="Formato Presupuesto">
												<i className="fas fa-print"></i>
											</button>
										</td>
									</tr>
								)}
							)
                        }
						</tbody>
                    </table>
					<Paginador setPage={this.setPage.bind(this)} pages={pages}/>
                </div>
                <DinamicModal 
					idModal					=	{'editPresupuesto'}
					sizeModalContainer		=	{'big'}
					title					=	{`Editar Presupuesto ${this.state.presupuesto.folio}`} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
                    loadingSave				=	{this.state.loading}
                    success					=	{this.editarPresupuesto.bind(this)} 
                    closeModal              =   {this.clearData.bind(this)}
					>
                        <PresupuestoForm
							values 		= 	{this.state.presupuesto}
							getValues	=	{(values) => this.formValues = values}
							clientes	=	{this.props.clientes}/>
                </DinamicModal>
            </div>
        )
    }
}

export default Consumer(TablaPresupuestos);