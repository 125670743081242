import React from "react";
import cogoToast from "cogo-toast";
import {Consumer} from "../../context";
import Request from "../../api/apiService";
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import SesionesForm from "../../components/sesiones/form";
import ListaAsistencia from "../../components/sesiones/listaAsistencia";

const req = new Request();

class TablaSesiones extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			itemSesion: [],
			sesion: {},
			fecha: [],
			listaAsistencia: [],
			loadingListaAsistencia:false
		};
	}

	async openToEditSesion(item) {
		await this.setState({sesion: item, fecha: item.fecha});
		this.openModal("editarSesion");
	}

	async openListaAsistencia(item) {
		await this.setState({sesion: item});
		this.loadListaAsistencia();
		this.openModal("listaAsistencia");
	}

	async loadListaAsistencia() {
		this.setState({loadingListaAsistencia: true});
		const {context} = this.props;
		const sesion = this.state.sesion;
		const data = {
			id_modulo: this.props.id_modulo,
			token: context.token,
			id_sesion: sesion.id_sesion,
		};

		const res = await req.get("sesiones/lista/asistencia", data);
		if (!res.error && !res.empty) {
			this.setState({listaAsistencia: res.sesiones});
		} else {
			this.setState({listaAsistencia: []});
		}
		this.setState({loadingListaAsistencia: false});
	}

	openModal(idModal) {
		const modal = document.getElementById(idModal);

		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-dinamic-modal");
		}
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		this.setState({sesion: {}, listaAsistencia: []});
		if (modal) {
			modal.classList.remove("show-dinamic-modal");
			modal.classList.add("hide-dinamic-modal");
		}
	}

	clearData() {
		this.setState({itemSesion: [], listaAsistencia: []});
	}

	updateState(json) {
		this.setState(json);
	}

	async editSession(event) {
		this.setState({loadingSave: true, message: ""});
		event.preventDefault();

		const {context} = this.props;

		const form = event.target;
		const sesion = this.state.sesion;

		const data = {
			id_modulo: this.props.id_modulo,
			token: context.token,
			materia: form.sesion_materia.value,
			comentario: form.sesion_comentario.value,
			horario: form.sesion_horario.value,
			id_sesion: sesion.id_sesion,
			fecha: this.state.fecha,
			nombre: form.sesion_nombre.value,
		};
		const res = await req.post("sesiones/update", data);

		if (res.updated) {
			cogoToast.success("Sesión agregada", {position: "top-right", heading: "AGREGADO!"});
			this.closeModal("editarSesion");
			this.props.reload();
		} else {
			cogoToast.error(res.message || "Error Interno.", {position: "top-right", heading: "OOPS!"});
			this.setState({message: data.message, loadingSave: false});
		}
		this.setState({loadingSave: false});
	}

	render() {
		let sesiones = [];
		if (Array.isArray(this.props.sesiones)) {
			sesiones = this.props.sesiones;
		}
		return (
			<div className="row table-container">
				<div className="column full">
					<div className="row full header-table">
						<div className="column full">
							<div className="row align-center">
								<i className="fas fa-th-list"></i>
								&nbsp;
								<span className="color-white weight-semibold">Lista de sesiones</span>
							</div>
						</div>
						<div className="row full justify-end">
							<div className="row input-search">
								<input className="" type="text" id="sesionesSearch" name="sesiones" placeholder="Buscar ..." maxLength="256" required></input>
								<i className="fas fa-search font-small icon-search"></i>
							</div>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="table-responsive">
						<table className="tabla-usuarios">
							<thead>
								<tr>
									<th className="text-left">Nombre</th>
									<th className="text-left">Materia</th>
									<th className="text-left">Horario</th>
									<th className="text-left">Vigente</th>
									<th className="text-left">Comentario</th>
									<th className="text-center"></th>
									<th className="text-center"></th>
								</tr>
							</thead>
							<tbody>
								{this.props.loading ? (
									<tr>
										<td colSpan="5">
											<div className="row justify-center">
												<i className="fas fa-sync fa-spin"></i>
											</div>
										</td>
									</tr>
								) : (
									sesiones.map((sesion, key) => {
										return (
											<tr key={key}>
												<td className="text-left">{sesion.nombre}</td>
												<td className="text-left">{sesion.materia}</td>
												<td className="text-left">{sesion.horario}</td>
												<td className="text-left">{sesion.vigente}</td> {/* faltará revisar la fecha para determinar si está vigente o no */}
												<td className="text-left">{sesion.comentarios}</td>
												<td className="text-center">
													<button className="btn-table-blue" onClick={this.openToEditSesion.bind(this, sesion)}>
														<i className="fas fa-edit"></i>
													</button>
												</td>
												<td className="text-center">
													<button className="btn-table-blue" onClick={this.openListaAsistencia.bind(this, sesion)}>
														<i className="fas fa-address-book"></i>
													</button>
												</td>
											</tr>
										);
									})
								)}
							</tbody>
						</table>
					</div>
				</div>
				<DinamicModal
					idModal={"editarSesion"}
					sizeModalContainer={"normal"}
					title={"Editar sesión"}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					success={this.editSession.bind(this)}
					closeModal={this.clearData.bind(this)}
				>
					<SesionesForm values={this.state.sesion} updateState={this.updateState.bind(this)} />
				</DinamicModal>
				<DinamicModal
					idModal={"listaAsistencia"}
					sizeModalContainer={"normal"}
					title={"Lista asistencia"}
					showBtnSuccess={false}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					success={() => {}}
					closeModal={this.clearData.bind(this)}
				>
					<ListaAsistencia listaAsistencia={this.state.listaAsistencia} loadingListaAsistencia={this.state.loadingListaAsistencia} />
				</DinamicModal>
			</div>
		);
	}
}

export default Consumer(TablaSesiones);
