import React, { useState, useEffect } from 'react';
import { Consumer } from '../../context/index';
import CheckBox from '../checkbox';

const PerfilForm = (props) => {
	const [prevProps, setPrevProps] = useState({});
    const [modulos, setModulos] = useState([]);

    useEffect(() => {
        const { context } = props;
        setModulos(context.modulos);
        if (props.getValues) {
            props.getValues(modulos);
		}
		if (props.values !== prevProps.values) {
			const { perfil, permisos } = props.values;
			const name = document.getElementById('perfil_name');
			if (name) {
				name.value = perfil
			}
			let pModulos = modulos;
			if (permisos) {
				if (Array.isArray(permisos.lectura)) {
					pModulos = pModulos.map(m => {
						if (permisos.lectura.find(l => m.id_modulo === l)) {
							m.lectura = true;
						} else {
							m.lectura = false;
						}
						return m;
					});
				} else {
					pModulos = pModulos.map(m => {
						m.lectura = false;
						return m;
					});
				}
				if (Array.isArray(permisos.escritura)) {
					pModulos = pModulos.map(m => {
						if (permisos.escritura.find(e => m.id_modulo === e)) {
							m.escritura = true;
						} else {
							m.escritura = false;
						}
						return m;
					});
				} else {
					pModulos = pModulos.map(m => {
						m.escritura = false;
						return m;
					});
				}
				setModulos(pModulos);
			} else {
				pModulos = pModulos.map(m => {
					m.lectura = false;
					m.escritura = false;
					return m;
				});
				setModulos(pModulos);
			}
			setPrevProps(props);
		}
    });

	function checkAll(permiso, checked) {
        setModulos(modulos.map(m => {
            m[permiso] = checked;
            return m;
        }));
    }

    function checkModulo(id_modulo, permiso, checked) {
        setModulos(modulos.map(m => {
            if (m.id_modulo === id_modulo) {
                m[permiso] = checked;
            }
            return m;
        }));
    }

    return (
        <div className="full row align-center perfil-form">
            <div className="column full">
                <div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Perfil :</p>
                    </div>
                    <div className="column full">
                        <input 
                            type			=	"text" 
							name			=	"name"
							id				=	"perfil_name"
                            className		=	"input input-modals" 
                            required				 
                            maxLength		=	"120"
                            autoComplete    =   "off"
                        />
                    </div>
                </div>
                <div className="white-space-16"></div>
                <div className="table-form-content row full align-center">
                    <table className="tabla-permisos">
                        <thead>
                            <tr>
                                <th className="text-left" style={{width: '50%'}}>Módulo</th>
                                <th className="text-center" style={{width: '25%'}}>
                                    Lectura &nbsp; <CheckBox onChange={checkAll.bind(this, 'lectura')}/>
                                </th>
                                <th className="text-center" style={{width: '25%'}}>
                                    Escritura &nbsp; <CheckBox onChange={checkAll.bind(this, 'escritura')}/>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {modulos.map(m => 
                                <tr key={m.id_modulo}>
                                    <td className="text-left" style={{width: '50%'}}>
                                        {m.modulo}
                                    </td>
                                    <td className="text-center" style={{width: '25%'}}>
                                        <CheckBox checked={m.lectura} onChange={checkModulo.bind(this, m.id_modulo, 'lectura')}/>
                                    </td>
                                    <td className="text-center" style={{width: '25%'}}>
                                        <CheckBox checked={m.escritura} onChange={checkModulo.bind(this, m.id_modulo, 'escritura')}/>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                
                <div className="white-space-32"></div>
        
            </div>
        </div>
    )
}

export default Consumer( PerfilForm );