import React from 'react';
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import PerfilForm from "../../components/perfiles/form";
import { Consumer } from '../../context';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import Request from '../../api/apiService';
const req = new Request();

const initPerfil = { id_perfil: 0, perfil: '', permisos: [], creado: {}, modificado: [] };

class TablaPerfiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            perfil: initPerfil
		}
		this.modulos = [];
    }


    openModal(idModal, item) {
        const modal = document.getElementById(idModal);
        this.setState({perfil: item});
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

    async editUser(event) {
        this.setState({loadingSave: true, message: ''});

        event.preventDefault();

        const form = event.target;
        const { context } = this.props;

        if (Array.isArray(this.modulos)) {
            const lectura = [], escritura = [];
            this.modulos.forEach(m => {
                if (m.lectura) {
                    lectura.push(m.id_modulo);
                }
                if (m.escritura) {
                    escritura.push(m.id_modulo);
                }
            });
			const permisos = { lectura, escritura };
			const id_usuario = await context.getId(context.token);
			const { perfil } = this.state;
			const modificado = Array.isArray(perfil.modificado) ? perfil.modificado : [];
			modificado.push({id_usuario, fecha: moment().format('YYYY-MM-DD HH:mm:ss')});
            const data = {
				id_modulo: this.props.id_modulo,
				id_perfil: perfil.id_perfil,
                nombre: form.name.value,
				permisos,
				creado: perfil.creado,
				modificado,
				token: context.token
            }
            const res = await req.post('perfil/editar', data);
            if(res.updated) {
                cogoToast.success('Perfil Actualizado', { position: 'top-right', heading: 'ACTUALIZADO!' });
                this.closeModal('editarPerfil');
                this.props.reload();
            } else {
				cogoToast.error(res.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
				this.setState({message: data.message, loadingSave: false});
			}
        }
        this.setState({loadingSave: false});
    }

    render() {
        let perfiles = [];
        if(Array.isArray(this.props.perfiles)) {
            perfiles = this.props.perfiles;
        }
        return (
            <div className="row table-container">
                <div className="column full">
                    <div className="row full header-table">
                        <div className="column full">
                            <div className="row">
                                <i className="fas fa-th-list"></i>
                                <span className="color-white weight-semibold">Lista de perfiles existentes</span>
                            </div>
                        </div>
                        <div className="row full justify-end">
                            <div className="row input-search">
                                <input className="" type="text" id="usuario" name="usuario" placeholder="Buscar ..." maxLength="256" required></input>
                                <i className="fas fa-search font-small icon-search"></i>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <table>
                        <thead>
                            <tr>
                                <th className="text-left">Perfil</th>
                                <th className="text-right">Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.loading?
                                <tr>
                                    <td colSpan="12">
                                        <div className="row justify-center">
                                            <i className="fas fa-sync fa-spin"></i>
                                        </div>
                                    </td>
                                </tr> :
                            
                                perfiles.map((perfil, key) => {
                                return(
                                    <tr key={key}> 
                                        <td className="text-left">{perfil.perfil}</td>
                                        <td className="text-right">
                                            <button className="btn-table-blue" onClick={this.openModal.bind(this,'editarPerfil', perfil)}>
                                                <i className="fas fa-user-edit"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            )
                        }
                        </tbody>
                    </table>
                </div>
                <DinamicModal 
					idModal					=	{'editarPerfil'}
					sizeModalContainer		=	{'normal'}
					title					=	{'Editar información del usuario'} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
                    loadingSave				=	{this.state.loadingSave}
                    success					=	{this.editUser.bind(this)} 
                    closeModal              =   {() => this.setState({perfil: initPerfil})}
					>
                        <PerfilForm
                            getValues	=	{(modulosForm) => this.modulos = modulosForm}
							values 		=	{this.state.perfil}/>
                </DinamicModal>
            </div>
        )
    }
}

export default Consumer(TablaPerfiles);