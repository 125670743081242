import React from "react";
import moment from "moment-timezone";
import cogoToast from "cogo-toast";

import {Consumer} from "../context";
import Request from "../api/apiService";
import Sidebar from "../components/sideBar";
import TablaSesiones from "../components/tables/sesionesTabla";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import SesionesForm from "../components/sesiones/form";
import Paginador from "../components/paginador/paginador";

const req = new Request();

class Sesiones extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id_modulo: 9, // supongo que va a quedar como 9
			loading: false,
			sesiones: [],
			usuario: {},
			fecha: [],
			page: 1,
		};
	}

	async componentDidMount() {
		await this.loadUser();
		this.loadSesiones();
	}

	setPage(page) {
		this.setState({page});
	}

	async openModal(idModal) {
		const modal = document.getElementById(idModal);
		await this.setState({
			sesion: {
				materia: "",
				horario: "",
				comentarios: "",
				fecha: [new Date(), new Date()],
			},
		});
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-dinamic-modal");
		}
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add("hide-dinamic-modal");
			modal.classList.remove("show-dinamic-modal");
		}
	}

	clearData() {
		this.setState({sesion: {}});
	}

	updateState(json) {
		this.setState(json);
	}

	async loadUser() {
		const mrd_user = window.localStorage.getItem("mrd_user");
		let session = JSON.parse(mrd_user);
		const data = {
			token: session.token,
		};
		const {token} = await req.post("decode", data);
		if (token) {
			this.setState({usuario: token});
		} else {
			cogoToast.error("No se pudieron obtener los datos del usuario.", {position: "top-right", heading: "OOPS!"});
		}
	}

	async loadSesiones() {
		this.setState({loading: true});
		const usuario = this.state.usuario;
		const {context} = this.props;
		const data = {
			id_modulo: this.state.id_modulo,
			token: context.token,
			id_usuario: usuario.id_usuario,
		};

		const res = await req.get("sesiones/table", data);
		if (!res.error && !res.empty) {
			res.sesiones = res.sesiones.map((sesion) => {
				sesion.fecha = sesion.fecha.map((fch) => new Date(fch));
				return sesion;
			});
			this.setState({sesiones: res.sesiones});
		} else {
			this.setState({sesiones: []});
		}

		this.setState({loading: false});
	}

	reload() {
		this.loadSesiones();
	}

	async addNewSession(event) {
		this.setState({loadingSave: true, message: ""});
		event.preventDefault();

		const {context} = this.props;

		if (this.state.fecha.length > 1) {
			const form = event.target;
			const usuario = this.state.usuario;

			const data = {
				id_modulo: this.state.id_modulo,
				token: context.token,
				materia: form.sesion_materia.value,
				comentario: form.sesion_comentario.value,
				horario: form.sesion_horario.value,
				id_usuario: usuario.id_usuario,
				fecha: this.state.fecha,
				nombre: form.sesion_nombre.value,
			};

			const res = await req.post("sesiones/create", data);

			if (res.created) {
				cogoToast.success("Sesión agregada", {position: "top-right", heading: "AGREGADO!"});
				this.closeModal("newSesion");
				this.reload();
			} else {
				cogoToast.error(res.message || "Error Interno.", {position: "top-right", heading: "OOPS!"});
				this.setState({message: data.message, loadingSave: false});
			}
		} else {
			cogoToast.error("Debe elegir un rango de fechas", {position: "top-right", heading: "OOPS!"});
		}

		this.setState({loadingSave: false});
	}

	render() {
		let sesiones = [];
		if (this.state.sesiones) {
			sesiones = this.state.sesiones;
		}
		return (
			<div className="row full view">
				<Sidebar view="sesiones"></Sidebar>
				<div className="column full align-center">
					<div className="container column">
						<div className="row full header-options align-center">
							<div className="column full title">
								<h1 className="weight-light color-white">Sesiones</h1>
							</div>
							<div className="column full btns-options align-end">
								<div className="row">
									<button
										className="btn-options btn-orange-plus"
										onClick={() => {
											this.openModal("newSesion");
										}}
									>
										<i className="fas fa-plus font-regular"></i>
									</button>
								</div>
							</div>
						</div>
						<TablaSesiones sesiones={sesiones.slice((this.state.page - 1) * 8, this.state.page * 8)} loading={this.state.loading} reload={this.reload.bind(this)} id_modulo={this.state.id_modulo} />
						<div className="row full">
							<div className="white-space-16"></div>
						</div>
						<Paginador page={this.state.page} pages={Math.ceil(sesiones.length / 8)} setPage={this.setPage.bind(this)} />
						<div className="white-space-48"></div>
					</div>
				</div>
				<DinamicModal
					idModal={"newSesion"}
					sizeModalContainer={"normal"}
					title={"Sesiones"}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					success={this.addNewSession.bind(this)}
					loadingSave={this.state.loadingSave}
					closeModal={this.clearData.bind(this)}
				>
					<SesionesForm values={this.state.sesion} updateState={this.updateState.bind(this)} />
				</DinamicModal>
			</div>
		);
	}
}

export default Consumer(Sesiones);
