import React from 'react';
import Sidebar from "../components/sideBar";
import TableUsuarios from "../components/tables/usuariosTabla";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import UsuarioForm from "../components/usuarios/form";
import { Consumer } from '../context';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import Request from '../api/apiService';
import Client from 'superagent';
import { API_URL, IMG_URL } from '../api/url';
const Spinner = require('react-spinkit');
const req = new Request();

class Usuarios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 3,
            loading: false,
			usuario: {
				nombre: '',
				correo: '',
				telefono: '',
				formacion: '',
				especialidades: ''
			},
			promiseUsuario: this.getPromiseId(),
			imagen: "/images/user-back.png",
			loadingSave: false
        }
    }

    async componentDidMount() {
		this.uploadFile();
		this.getProfileImage();
		this.loadUsuario();
	}

	async getPromiseId() {
		const { context } = this.props;
		const id = await context.getId(context.token);
		this.setState({id_usuario: id});
		return id;
	}

	async loadUsuario() {
		this.setState({loading: true});
		const { id_modulo } = this.state;
		const id_usuario = await Promise.resolve(this.state.promiseUsuario);
		const result = await req.get(`usuario/obtener/${id_usuario}`, {id_modulo});
		if (result.usuario) {
			this.setState({usuario: result.usuario});
		} else {
			cogoToast.error(result.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
		}
		this.setState({loading: false});
	}

	async getProfileImage() {
		const id_usuario = await Promise.resolve(this.state.promiseUsuario);
		this.setState({imagen: `${IMG_URL}usuario/imagen/${id_usuario}`});
	}

	async uploadFile() {
		const { context } 	= this.props;
		const fileSelect	= document.getElementById('file-upload');
		const fileDrag      = document.getElementById('file-drag');

		function Init() {
			fileSelect.addEventListener('change', fileSelectHandler, false);

			// Is XHR2 available?
			var xhr = new XMLHttpRequest();
			if (xhr.upload) {
				// File Drop
				fileDrag.addEventListener('dragover', fileDragHover, false);
				fileDrag.addEventListener('dragleave', fileDragHover, false);
				fileDrag.addEventListener('drop', fileSelectHandler, false);
			}
		}

		function fileDragHover(e) {
			var fileDrag = document.getElementById('file-drag');
		
			e.stopPropagation();
			e.preventDefault();
		
			fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
		}

		function fileSelectHandler(e) {
			// Fetch FileList object
			var files = e.target.files || e.dataTransfer.files;
		
			// Cancel event and hover styling
			fileDragHover(e);
		
			// Process all File objects
			for (var i = 0, f; f = files[i]; i++) {
				parseFile(f);
				uploadFile(f);
			}
		}

		// Output
		function output(msg) {
			// Response
			var m = document.getElementById('messages');
			m.innerHTML = msg;
		}

		function parseFile(file) {
			output(
				'<strong>' + encodeURI(file.name) + '</strong>'
			);
	
			var imageName = file.name;
		
			var isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName);
			if (isGood) {
				document.getElementById('start').classList.add("hidden");
				document.getElementById('response').classList.remove("hidden");
				document.getElementById('notimage').classList.add("hidden");
				// Thumbnail Preview
				document.getElementById('file-image').classList.remove("hidden");
				document.getElementById('file-image').src = URL.createObjectURL(file);
			}
			else {
				document.getElementById('file-image').classList.add("hidden");
				document.getElementById('notimage').classList.remove("hidden");
				document.getElementById('start').classList.remove("hidden");
				document.getElementById('response').classList.add("hidden");
				document.getElementById("file-upload-form").reset();
			}
		}

		function setProgressMaxValue(e) {
			var pBar = document.getElementById('file-progress');
		
			if (e.lengthComputable) {
				pBar.max = e.total;
			}
		}

		function updateFileProgress(e) {
			var pBar = document.getElementById('file-progress');
		
			if (e.lengthComputable) {
				pBar.value = e.loaded - (e.total * 0.2);
			}
		}

		function resetFileUpload() {
			document.getElementById("file-upload-form").reset();
			document.getElementById('start').classList.remove("hidden");
			document.getElementById('response').classList.add("hidden");
		}

		async function uploadFile(file) {
			Client.put(API_URL + 'usuario/upload/imagen_perfil')
			.set('api_key', 'cpk0311243q6')
			.attach('profileImage', file)
			.field('token', context.token)
			.on('progress', event => {
				console.log(event);
				setProgressMaxValue(event);
				updateFileProgress(event);
			})
			.set('auth', context.token)
			.then(res => {
				const result = res.body;
				console.log(result);
                if (result) {
					if (result.uploaded) {
						var pBar = document.getElementById('file-progress');
						if (pBar) {
							const setProgress = setInterval(() => {
								if (pBar.value < pBar.max) {
									pBar.value = pBar.value + 1
								} else {
									clearInterval(setProgress);

								}
							}, 50);
						}
						resetFileUpload();
						cogoToast.success('Imágen actualizada.', { position: 'top-right' });
					} else {
						var pBar = document.getElementById('file-progress');
						if (pBar) {
							const setProgress = setInterval(() => {
								if (pBar.value > 0 ) {
									pBar.value = pBar.value - 1
								} else {
									resetFileUpload();
									clearInterval(setProgress);
								}
							}, 25);
						}
						cogoToast.error(result.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
					}
				} else {
					cogoToast.error(result.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
				}
            })
            .catch(err => {
				if (err.status === 403) {
					err.message = 'Error de permisos.';
				}
				cogoToast.error(err.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
				resetFileUpload();
				return { error: true, message: err.message};
            });
		}

		// Check for the various File API support.
		if (window.File && window.FileList && window.FileReader) {
			Init();
		} else {
			document.getElementById('file-drag').style.display = 'none';
		}
	}

	async onSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		const { context } = this.props;
		const id_usuario = await context.getId(context.token);
		let { usuario } = this.state;
		const modificado = Array.isArray(usuario.modificado) ? usuario.modificado : [];
		modificado.push({id_usuario, fecha: moment().format('YYYY-MM-DD HH:mm:ss')});
		usuario = {
			...usuario,
			token: context.token,
			fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
			modificado
		}
		const data = {
			id_modulo: this.state.id_modulo,
			id_usuario,
			usuario
		}
		const result = await req.post('usuario/editar/perfil', data);
		if (result.updated) {
			cogoToast.success('Perfil actualizado.', { position: 'top-right' });
		} else {
			cogoToast.error(result.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
		}
		this.setState({loadingSave: false});
	}

	setValue(item, event) {
		const usuario = this.state.usuario;
		usuario[item] = event.target.value;
		this.setState({usuario});
	}

	imgError(img) {
		img.target.src =  "/images/user-back.png";
	}

    render() {
		const id_usuario = this.state.id_usuario;
        return (
            <div className="row full view">
                <Sidebar view="usuarios"></Sidebar>
                <div className="column full align-center">
                    <div className="container column">
                        <div className="row full header-options align-center">
                            <div className="column full title">
                                <h1 className="weight-light color-white">Mi Perfil</h1>
                            </div>
                        </div>
						<div className="row-responsive  full full-height">
							<form className="column full full-height" onSubmit={this.onSubmit.bind(this)}>
								<div className="column full justify-center align-center">
									<div className="column full title">
										<p className="color-white"> Nombre* :</p>
									</div>
									<input
										className="input input-text"
										required
										placeholder="Nombre"
										value={this.state.usuario.nombre}
										onChange={this.setValue.bind(this, 'nombre')}/>
									<div className="white-space-8"></div>
									<div className="column full title">
										<p className="color-white"> Correo* :</p>
									</div>
									<input
										className="input input-text"
										type="email"
										required
										placeholder="Correo electronico"
										value={this.state.usuario.correo}
										onChange={this.setValue.bind(this, 'correo')}/>
									<div className="white-space-8"></div>
									<div className="column full title">
										<p className="color-white"> Teléfono* :</p>
									</div>
									<input
										className="input input-text"
										type="phone"
										required
										placeholder="Numero teléfonico"
										value={this.state.usuario.telefono}
										onChange={this.setValue.bind(this, 'telefono')}/>
									<div className="white-space-8"></div>
									<div className="column full title">
										<p className="color-white"> Formación :</p>
									</div>
									<textarea
										rows="5"
										maxLength="500"
										className="input input-text-area"
										value={this.state.usuario.formacion}
										onChange={this.setValue.bind(this, 'formacion')}/>
									<div className="white-space-8"></div>
									<div className="column full title">
										<p className="color-white"> Especialidades :</p>
									</div>
									<textarea
										rows="5"
										maxLength="500"
										className="input input-text-area"
										value={this.state.usuario.especialidades}
										onChange={this.setValue.bind(this, 'especialidades')}/>
									<div className="white-space-16"></div>
									<button className="btn btn-blue color-white" type="submit" disabled={this.state.loadingSave}>
										{this.state.loadingSave? 
											<Spinner name="ball-beat" color="aqua" fadeIn="none"/>
											:
											'Actualizar'
										}
									</button>
								</div>
							</form>
							<div className="column full justify-center align-center">
								<div className="profile-photo">
									<img src={`${IMG_URL}usuario/imagen/${id_usuario}`} id="file-image" onError={this.imgError.bind(this)}></img>
								</div>
								<div className="white-space-8"></div>
								<div className="column upload">
									<form id="file-upload-form" className="uploader">
										<input id="file-upload" type="file" name="fileUpload" accept="image/*" />
										<label htmlFor="file-upload" id="file-drag">
											<div id="start">
												<i className="fa fa-download" aria-hidden="true"></i>
												<div>Selecciona o arrastra un archivo</div>
												<div id="notimage" className="hidden">Selecciona una imagen válida</div>
											</div>
											<div id="response" className="hidden">
												<div id="messages"></div>
												<progress className="progress" id="file-progress" value="0">
													<span>0</span>%
												</progress>
											</div>
										</label>
									</form>
								</div>
							</div>
						</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Consumer(Usuarios);