import React from 'react';
import Sidebar from "../components/sideBar";
import TableOrdenesTrabajo from "../components/tables/ordenesTrabajo";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import OrdenTrabajoForm from "../components/ordenTrabajo/form";
import { Consumer } from '../context';
// TOASR ALERTS & LOADING SPINERS
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import Request from '../api/apiService';
const req = new Request();

class Presupuestos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 2,
			loading: false,
			lectura: false,
			escritura: false,
			ordenesTrabajo: [],
			presupuestos: []
		}
		this.formValues = {};
    }

    componentDidMount() {
		this.loadUser();
		this.loadPresupuestos();
	}
    
    openModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

	async loadUser() {
        const mrd_user = window.localStorage.getItem("mrd_user");
		let session = JSON.parse(mrd_user);

		const data = {
            token: session.token
		}
        const { token } = await req.post('decode', data);
        if (token) {
			this.setState({usuario: token});
			const { permisos } = token;
			if(permisos) {
				if(permisos.lectura.find(l => l === this.state.id_modulo)) {
					this.setState({lectura: true});
				}
				if(permisos.escritura.find(e => e === this.state.id_modulo)) {
					this.setState({escritura: true});
				}
				this.loadOrdenesTrabajo();
			}
		} else {
			cogoToast.error('No se pudieron obtener los permisos.', { position: 'top-right', heading: 'OOPS!' });
		}
	}

    async loadOrdenesTrabajo() {
		this.setState({loading: true, message: ''});
		if (!this.state.lectura) {
			cogoToast.error('No tiene permisos de lectura.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({loading: false});
			return;
		}
        const data = {
            id_modulo: this.state.id_modulo
        }

        const res = await req.get('orden_trabajo/tabla', data);
        this.setState({message: res.message});
        if(res) {
            this.setState({ ordenesTrabajo: res.ordenesTrabajo });
        } else {
            cogoToast.error('No se pudieron obtener las S.I./O.C.', { position: 'top-right', heading: 'OOPS!' });
        }
        this.setState({loading: false});
    }

	async loadPresupuestos() {
        const data = {
            id_modulo: this.state.id_modulo
        }
        const res = await req.get('presupuestos/lista', data);
		this.setState({message: res.message});
        if(res) {
            this.setState({ presupuestos: res.presupuestos });
        } else {
            cogoToast.error('No se pudo obtener la lista de presupuestos.', { position: 'top-right', heading: 'OOPS!' });
        }
    }

    reload() {
        this.loadUser();
    }

    async addNewOrdenTrabajo(event) {
        this.setState({loading: true, message: ''});
		event.preventDefault();
		const { context } = this.props;
		const data = {
			id_modulo: this.state.id_modulo,
			fecha: this.formValues.fecha,
			concepto: this.formValues.concepto,
			id_presupuesto: this.formValues.id_presupuesto,
			importe: this.formValues.importe,
			token: context.token,
			fecha_creado: moment().format('YYYY-MM-DD HH:mm:ss')
		}
		const res = await req.post('orden_trabajo/crear', data);
		if(res.created) {
			cogoToast.success('Nuevo O.C./S.I. Agregado', { position: 'top-right', heading: 'AGREGADO!' });
			this.closeModal('nuevaOrdenTrabajo');
			this.reload();
		} else {
			cogoToast.error(res.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
        this.setState({loading: false});
    }

    render() {
        let ordenesTrabajo = [];
		if(this.state.ordenesTrabajo) {
			ordenesTrabajo = this.state.ordenesTrabajo;
		}
        return (
            <div className="row full view">
                <Sidebar view="ordenesTrabajo"></Sidebar>
                <div className="column full align-center">
                    <div className="container column">

                        <div className="row full header-options align-center">
                            <div className="column full title">
                                <h1 className="weight-light color-white">S.I. / O.T.</h1>
                            </div>
                            <div className="column full btns-options align-end">
                                <div className="row">
									<button
										className	=	"btn-options btn-orange-plus"
										data-title	=	"Agregar O.C./S.I."
										onClick		=	{this.openModal.bind(this,'nuevaOrdenTrabajo')} >
										<i className="fas fa-plus font-regular" ></i>
									</button>
                                </div>
                            </div>
                        </div>
                        <TableOrdenesTrabajo
							ordenesTrabajo	=	{ordenesTrabajo}
							loading			=	{this.state.loading}
							reload			=	{this.reload.bind(this)}
							presupuestos	=	{this.state.presupuestos}/>
                    </div>
                </div>
                <DinamicModal 
					idModal					=	{'nuevaOrdenTrabajo'}
					sizeModalContainer		=	{'normal'}
					title					=	{'Cargar O.C. / S.I.'} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
                    btnTextCancel			=	{"CANCELAR"}
                    success                 =   {this.addNewOrdenTrabajo.bind(this)}
					loadingSave				=	{this.state.loading}
					>
                        <OrdenTrabajoForm
							getValues		=	{(values) => this.formValues = values}
							presupuestos	=	{this.state.presupuestos}/>
                </DinamicModal>
            </div>
        )
    }
}

export default Consumer(Presupuestos);
