import React from "react";
import moment from "moment-timezone";
import {IMG_URL} from "../../api/url";
const RowSesion = (props) => {
	const CutComentario = (comentario) => {
		let result = comentario.slice(0, 70);
		return result + (comentario.length > 70 ? " ..." : "");
	};

	const imgError = (img) => {
		img.target.src = "/images/user-back.png";
	};

	return props.sesiones.length == 0 && !props.loading ? (
		<div className="row-responsive full row-sesion justify-center align-center">
			<p className="color-white weight-medium">No se encontraron cursos.</p>
		</div>
	) : props.loading ? (
		<div className="row-responsive full row-sesion justify-center align-center">
			<i className="fas fa-sync fa-spin"></i>
		</div>
	) : (
		props.sesiones.map((sesion, index) => {
			return (
				<div key={index} className="row-responsive full row-sesion">
					<div className="column half img-sesion align-center justify-center">
						<div className="row full align-center justify-center">
							<div className="responsive-img">
								<img src={`${IMG_URL}imagen?path=${sesion.imagen}`} onError={imgError.bind(this)} alt="" />
							</div>
						</div>
						<div className="row full">
							<div className="white-space-8"></div>
						</div>
						<div className="row full align-center justify-center">
							<p className="color-white weight-medium">{sesion.profesor}</p>
						</div>
					</div>
					<div className="column full detail-sesion justify-center align-center padding-side-16">
						<div className="row full">
							<div className="white-space-8"></div>
						</div>
						<div className="row justify-center">
							<p className="color-white font-large weight-medium">{sesion.nombre}</p>
						</div>
						<div className="row full">
							<div className="white-space-8"></div>
						</div>
						<div className="row justify-center">
							<p className="color-white text-justify font-mini ">{CutComentario(sesion.comentarios)}</p>
						</div>
						<div className="row full">
							<div className="white-space-8"></div>
						</div>
						<div className="row justify-center">
							<p className="color-white weight-medium">
								{moment(sesion.fecha[0]).format("DD/MM/YYYY")} - {moment(sesion.fecha[1]).format("DD/MM/YYYY")}
							</p>
						</div>
						<div className="row full">
							<div className="white-space-8"></div>
						</div>
						<div className="row justify-center">
							<p className="color-white weight-medium">{sesion.horario}</p>
						</div>
						<div className="row full">
							<div className="white-space-16"></div>
						</div>
					</div>
					<div className="column half join-sesion justify-center align-center padding-left-16 padding-right-16">
						{sesion.inscrito > 0 ? (
							<button className="btn-options btn-gray full">Inscrito en curso</button>
						) : (
							<button
								className="btn-options btn-orange-plus full"
								onClick={() => {
									props.openModal(sesion);
								}}
							>
								Ver más...
							</button>
						)}
					</div>
				</div>
			);
		})
	);
};

export default RowSesion;
